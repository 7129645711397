import React, { useEffect, useState } from "react";
import Accreditation from "./aboutUsSubTabs/Accreditation";
import OurPurpose from "./aboutUsSubTabs/OurPurpose";
import OurTeam from "./aboutUsSubTabs/OurTeam";
import ComponentListSubTabsComponent from "./subTabs/ComponenListSubTabs";
import "./aboutUs.scss";

export default function AboutUsComponent({ ChangeShowDetailHandler }) {
    const [aboutUsKey, setAboutUsKey] = useState('ABOUT_US');

    useEffect(() => {
        let tabVal = localStorage.getItem("aboutUsKey");
        if (tabVal) {
            setAboutUsKey(tabVal);
        }
    }, []);

    const changeAboutUsTab = (tabValue) => {
        setAboutUsKey(tabValue)
        localStorage.setItem("aboutUsKey", tabValue)
    }


    return (
        <>
            <ComponentListSubTabsComponent data={[
                {
                    title: 'Our Purpose',
                    key: 'ABOUT_US',
                    component: <OurPurpose activeKey={aboutUsKey} />
                },
                {
                    title: 'Our Story',
                    key: 'OUR_STORY',
                    component: <OurPurpose activeKey={aboutUsKey} />
                },
                {
                    title: 'Our Team',
                    key: 'OUR_TEAM',
                    component: <OurTeam activeKey={aboutUsKey} />
                },
                {
                    title: 'Our Client',
                    key: 'OUR_CLIENT',
                    component: <OurTeam activeKey={aboutUsKey} />
                },
                {
                    title: 'Accreditations',
                    key: 'ACCREDITATIONS_AWARDS',
                    component: <Accreditation activeKey={aboutUsKey} />
                },
            ]} activeKey={aboutUsKey} handleSubTabChange={changeAboutUsTab} />
        </>
    );
}

