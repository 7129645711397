import React from "react";

export default function PageNotFound({ message, showLinks, links = [], }) {
  return (
    <div
      className="not-found-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#9c9c9d",
        fontSize: "20px",
        width: "95%",
        fontFamily: "AirbnbCereal-Book",
        textAlign: 'center'
      }}
    >
      <div className="text-medium">{message}</div>
      {showLinks &&
        <div>
          {
            links.map((ele) => {
              return <p>{ele?.message || ''}{" "}
                <a href={ele?.link || ''}>{ele.title || ''}</a>
              </p>
            })
          }
        </div>
      }
    </div>
  );
}

PageNotFound.defaultProps = {
  message: "Oops...! The page you're looking for does not exist",
  showLinks: false,
  links: [{ message: '', title: 'Home', link: "/" }]
};
