import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../../../api/http";
import userapiservice from "../../../../api/userapiservice";
import Loader from "../../../../components/Loader/Loader";
import DefaultThumbnail_1X1 from '../../../../assets/newImages/1X1.svg'
import "./ourTeam.scss";

export default function OurTeam({ activeKey }) {
    const [loading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const id = localStorage.getItem("micrositeID");
    const navigate = useNavigate();

    const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];
    const getAboutData = async (activeKey, id) => {
        try {
            setLoading(true);
            if (id) {
                const response = await getRequest(
                    userapiservice.getAboutUsDetailsTabs(id, activeKey)
                );
                setResponseData(response);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeKey && id) {
            getAboutData(activeKey, id);
        }
    }, []);

    const onCardClickHandler = (data) => {
        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: { page: activeKey, data: data },
        });
    };

    if (loading) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className="our-team-container">
                <div className="card-container">
                    {responseData && responseData.length > 0
                        ?
                        responseData.map((data, i) => {
                            console.log('data', data)
                            return (
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        duration: 0.8,
                                        delay: 0.5,
                                        ease: [0, 0.71, 0.2, 1.01],
                                    }}
                                    className="card-item"
                                    onClick={() => {
                                        onCardClickHandler({
                                            ...data, description: data.aboutUsTeamDataOutPut.bio,
                                            mediaLink: data.aboutUsTeamDataOutPut.image
                                        });
                                    }}
                                >
                                    <div className="text-container"   >
                                        <div className="card-title" >
                                            {/* {data?.aboutUsTeamDataOutPut?.linkedin} */}
                                            {data?.title ? data?.title : activeKey === 'OUR_TEAM' ? 'Our Team' : 'Our Client'}
                                        </div>
                                        <p className="card-description">

                                            {data?.aboutUsTeamDataOutPut?.bio}
                                        </p>

                                    </div>
                                    <div className="image-container" onClick={() => {
                                        if (data?.webLink) { window.open(data?.webLink, "_blank") }
                                    }} >
                                        <img
                                            src={data?.aboutUsTeamDataOutPut?.image?.url || DefaultThumbnail_1X1}
                                            alt="image"
                                            className="home-preview-img"
                                        />

                                    </div>
                                    <div className="color-band" style={{ backgroundColor: color[i] }} />
                                </motion.div>
                            );
                        })
                        : <div className="info-container" > Nothing here at the moment </div>}
                </div>


            </div >
        );
    }
}
