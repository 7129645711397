import React, { useEffect, useState } from 'react'
import { Card, Button, Modal, Spin } from "antd";
import userapiservice from "../../api/userapiservice";
import axios from 'axios';
import { truncateFilename } from '../../utils/helper';
import { CloseOutline } from 'antd-mobile-icons';
import './viewAndDownloadBrochure.scss'
import { Document, Page, pdfjs } from 'react-pdf';
import { useMediaQuery } from "react-responsive";
import "./viewAndDownloadBrochure.scss"


const ViewAndDownloadBrochure = ({ state, onLoadComplete }) => {
  const [viewLoading, SetViewLoading] = useState();
  const [downloadLoading, SetDownloadLoading] = useState();
  const isSmallScreen = useMediaQuery({ minWidth: 320, maxWidth: 767 });

  const [fileType, setFileType] = useState();
  const [pdfContent, setPdfContent] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  const [pdfDataResponse, setPdfDataResponse] = useState();
  const [pdfDataResponseLoading, setPdfDataResponseLoading] = useState(true);

  const [pdfFirstPageUrl, setPdfFirstPageUrl] = useState("");

  useEffect(() => {
    const currentHost = window.location.host;
    const protocol = window.location.protocol;
    let workerSrc = `${protocol}//${currentHost}/pdf.worker.min.js`;
    console.log("workerSrc", workerSrc);
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      workerSrc,
      import.meta.url
    ).toString();
  }, []);

  useEffect(() => {
    const getPdfBlob = async () => {
      setPdfDataResponseLoading(true);
      try {
        const authToken = localStorage.getItem("accessToken");
        const response = await axios.get(
          userapiservice.downloadBrochure(state.data.id),
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            responseType: "blob",
          }
        );
        if (response) {
          setPdfDataResponse(response);

          //////////////////////////////////
          if (response.data instanceof Blob) {
            const blob = response.data;
            let reader = new FileReader();

            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64String = reader.result;
              setPdfString(base64String.substr(base64String.indexOf(",") + 1));
            };
          } else {
            console.error("Response data is not a Blob");
          }
          //////////////////////////////////
        }
      } catch (error) {
        console.log("error downloading pdf", error);
      } finally {
        setPdfDataResponseLoading(false);
        onLoadComplete();
      }
    };
    getPdfBlob();
  }, [state]);

  const getDownloadBrochureData = async () => {
    SetDownloadLoading(true);
    try {
      if (pdfDataResponse) {
        const fileName = state.data.brochureFileName
          ? state.data.brochureFileName
          : `${state.data.assetTitle}.pdf`;
        const blob = new Blob([pdfDataResponse.data]);
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.log("error downloading pdf from blob", error);
    } finally {
      SetDownloadLoading(false);
    }
  };

  const getViewBrochureData = async () => {
    SetViewLoading(true);
    try {
      if (pdfDataResponse) {
        setFileType(pdfDataResponse.data.type);
        const blob = new Blob([pdfDataResponse.data], {
          type: "application/pdf",
        });
        const pdfUrl = URL.createObjectURL(blob);
        setPdfContent(pdfUrl);
        setViewModalVisible(true);
      }
    } catch (error) {
      console.log("error viewing pdf from blob", error);
    } finally {
      SetViewLoading(false);
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfString, setPdfString] = useState("");

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div
      className="brochure"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: isSmallScreen && "10px",
        // marginLeft:"12vw"
      }}
    >
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf_viewer.min.css"
      />

      <Card className="brochure-card">
        <h4
          className="brochured-header-text"
          style={{
            paddingLeft: isSmallScreen && "10px",
            margin: 0,
            marginBottom: "10px",
          }}
        >
          Brochure
        </h4>
        {pdfDataResponseLoading ? (
          <div className="no-data-warning">
            <Spin tip="Loading" size="large" />
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <div
              style={{
                border: "1px solid #0000001f",
                borderRadius: "6px",
                padding: "10px",
              }}
            >
              {pdfDataResponse && !pdfString && (
                <img
                  className="fade-in"
                  style={{ width: "200px" }}
                  src={
                    pdfFirstPageUrl !== ""
                      ? pdfFirstPageUrl
                      : state.data.micrositeLogo
                  }
                  alt="pdf First Page"
                />
              )}

              <div style={{ width: "250px" }}>
                {pdfDataResponse && pdfString && (
                  <Document
                    file={`data:application/pdf;base64,${pdfString}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="fade-in"
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                )}
              </div>
              {/* 
                <h3
                  className="pdf-file-name"
                  style={{ fontSize: "16px", textAlign: "center" }}
                >
                  {state.data.brochureFileName
                    ? truncateFilename(state.data.brochureFileName, 20)
                    : `${state.data.assetTitle}.pdf`}
                </h3> */}
              <div
                className="brochure-buttons"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  className="brochure-bt"
                  style={{ background: "#3067AE" }}
                  loading={viewLoading}
                  onClick={getViewBrochureData}
                  type="primary"
                >
                  View
                </Button>
                <Button
                  className="brochure-bt"
                  style={{ background: "#3067AE" }}
                  loading={downloadLoading}
                  type="primary"
                  onClick={getDownloadBrochureData}
                >
                  Download
                </Button>
              </div>
            </div>
          </div>
        )}
      </Card>

      {viewModalVisible && (
        <>
          <div
            className="custom-modal-overlay"
            onClick={() => {
              setViewModalVisible(false);
            }}
          ></div>
          <div
            className="custom-modal"
            style={{ width: isSmallScreen ? "90vw" : "80vw" }}
          >
            <div className="custom-modal-header">
              <span className="custom-modal-title">View Attachment</span>
              <button
                className="custom-close-button"
                onClick={() => {
                  setViewModalVisible(false);
                }}
              >
                <CloseOutline />
              </button>
            </div>
            <div>
              {console.log("pdfContent", pdfContent, "fileType", fileType)}
              {pdfContent && fileType === "application/pdf" && (
                <iframe
                  className="pdf-viewer"
                  src={pdfContent}
                  title="PDF Viewer"
                  // style={{ width: '100%', height: '70vh', position: 'relative' }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewAndDownloadBrochure