import { Tabs } from "antd";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setMicrositeDetails, renderList } from "../../../Reducer/globalSlice";
import { deleteRequest, getRequest, postRequest } from "../../../api/http";
import userapiservice from "../../../api/userapiservice";
import { Back, Favorite, FavoriteActive, TabLeftArrow, TabRightArrow } from "../../../components/Icons/Icons";
import Loader from "../../../components/Loader/Loader";
import PageNotFound from "../../../components/PageNotFound/PageNotFound";
import PartnerCarousel from "../../partnerScreen/partnerSlider";
import ContactUsComponent from "../ContactUsComponent";
import News from "../News";
import ApprenticeshipsComponent from "../apprenticeshipsComponent";
import Contracts from "../contracts";
import Courses from "../courses";
import ExperienceOfWork from "../experienceOfWork";
import Products from "../products";
import Services from "../services";
import TeamsAndRoles from "../teamsAndRoles";
import Vacancies from "../vacancies";
import WhyWork from "../whyUs";
import AboutUsComponent from "./AboutUs";
import "./newMicrosite.scss";

const NewMicrosite = () => {
  const [hideBackBtn, setHideBackBtn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [microSiteId, setMicroSiteId] = useState(null);
  const [micrositeName, setMicrositeName] = useState("");
  const [filteredTabs, setFilteredTabs] = useState([]);
  const [position, setPosition] = useState(["left", "right"]);

  const [logo, setLogo] = useState();
  const [carouselData, setCarouselData] = useState([]);
  const [showVac, setShowVac] = useState(false);

  const [activeKey, setActiveKey] = useState("about-us");
  const [siteSetting, setSiteSitting] = useState(null);
  const [allBookmarkedSites, setAllBookmarkedSites] = useState([])
  const [bookmarked, setBookmarked] = useState(false);


  // using useEffect to set values from local storage
  const [partner, setPartner] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [micrositeId, setMicrositeId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [age, setAge] = useState("");
  const [micrositeNotFound, setMicrositeNotFound] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeTab = (tabValue) => {
    localStorage.setItem("tabValue", tabValue);
    setActiveKey(tabValue);
  };
  const isShared = useSelector(state => state.global.isShared)
  const reduxGlobalState = useSelector(state => state.global)

  const TAB_KEYS = {
    aboutUs: "about-us",
    product: "product",
    service: "service",
    contract: "contract",
    contactUs: "contact-us",
    EXPERIENCES_OF_WORK: "EXPERIENCES_OF_WORK",
    apprenticeship: "apprenticeship",
    vacancy: "vacancy",
    courses: "courses",
    whyWork: "why-work",
    teamsAndRoles: "teamsAndRoles",
    workExperience: "work-experience",
    news: "News",
    press: "press",
    // corousel: "corousel",
  };
  const allTabsList = [
    {
      tabName: "About Us",
      tabKey: TAB_KEYS.aboutUs,
      settingsKey: null,
      default: true,
      hasSubTabs: true,
      component: <AboutUsComponent ChangeShowDetailHandler={null} />,
    },
    {
      tabName: "Products",
      tabKey: TAB_KEYS.product,
      settingsKey: "products",
      default: false,
      hasSubTabs: true,
      component: <Products />,
    },
    {
      tabName: "Services",
      tabKey: TAB_KEYS.service,
      settingsKey: "services",
      default: false,
      component: <Services />,
    },
    {
      tabName: "Contract",
      tabKey: TAB_KEYS.contract,
      settingsKey: "contracts",
      default: false,
      component: <Contracts />
    },
    {
      tabName: "Contact Us",
      tabKey: TAB_KEYS.EXPERIENCES_OF_WORK,
      settingsKey: null,
      default: true,
      hasSubTabs: true,
      component: <ContactUsComponent />
    },
    {
      tabName: "Apprenticeships",
      tabKey: TAB_KEYS.apprenticeship,
      settingsKey: "apprenticeships",
      default: false,
      component: <ApprenticeshipsComponent />
    },
    {
      tabName: "Vacancies",
      tabKey: TAB_KEYS.vacancy,
      settingsKey: "vacancies",
      default: false,
      component: <Vacancies />,
    },
    {
      tabName: "Courses",
      tabKey: TAB_KEYS.courses,
      settingsKey: "courses",
      default: false,
      hasSubTabs: true,
      component: <Courses />,
    },
    {
      tabName: "Why work for us",
      tabKey: TAB_KEYS.whyWork,
      settingsKey: "whyWork",
      default: false,
      component: <WhyWork />,
    },
    {
      tabName: "Our teams and roles",
      tabKey: TAB_KEYS.teamsAndRoles,
      settingsKey: "teamsAndRoles",
      default: false,
      hasSubTabs: true,
      component: <TeamsAndRoles />
    },
    {
      tabName: "Experiences of work",
      tabKey: TAB_KEYS.workExperience,
      settingsKey: "experienceOfWork",
      default: false,
      component: <ExperienceOfWork
        changeTab={changeTab}
      />
    },
    {
      tabName: "Blog",
      tabKey: TAB_KEYS.news,
      settingsKey: "newsTab",
      default: false,
      component: <News activeKey={'news_tab'} />

    },
    {
      tabName: "In the press",
      tabKey: TAB_KEYS.press,
      settingsKey: "press",
      default: false,
      component: <News activeKey={'press'} />
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setAccessToken(localStorage.getItem("accessToken"));
    }
    if (localStorage.getItem("partner")) {
      setPartner(localStorage.getItem("partner"));
    }
    if (localStorage.getItem("partnerId")) {
      setPartnerId(localStorage.getItem("partnerId"));
    }
    if (localStorage.getItem("micrositeID")) {
      setMicrositeId(localStorage.getItem("micrositeID"));
    }
    if (localStorage.getItem("Phone Number")) {
      setPhoneNumber(localStorage.getItem("Phone Number"));
    }
    if (localStorage.getItem("age")) {
      setAge(localStorage.getItem("age"));
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get("partnerId")) {
      setHideBackBtn(false);
      localStorage.setItem("sharedSite", false);
    }
    if (urlParams.get("partnerId")) {
      setPartnerId(urlParams.get("partnerId"));
      // set as shared site to hide login/logout drop down in micrositeDetails
      localStorage.setItem("sharedSite", true);
    }
  }, []);

  // Get microsite ID using the microsite name from URL path
  const getMicrositeDetails = async () => {
    try {
      setLoading(true)
      const micrositeName = location.pathname.split("/")[2];
      const response = await getRequest(
        userapiservice.getMicrositeDetailsByName(micrositeName.replace(/_/g, " "))
      );
      if (response) {

        setMicroSiteId(response[0].id);
        localStorage.setItem("micrositeID", response[0].id);
      }
    } catch (error) {
      console.log('getMicrositeDetails', error.response.status)
      toast.error(error.error);
      if (error.response.status === 400) {
        setMicrositeNotFound(true)
      }
    }
    finally {
      setLoading(false)
    }
  };

  // Get header logo
  const getMicrositeLogo = async () => {

    try {
      setLoading(true)
      const response = await getRequest(userapiservice.getMicrositeLogo(microSiteId));
      dispatch(setMicrositeDetails({ micrositeLogo: response?.mediaLink, micrositeName: response?.name }))
      setMicrositeName(response?.name)
      setLogo(response?.mediaLink);
    } catch (error) {
      toast.error(error.error);
    }
    finally {
      setLoading(false)
    }
  };

  // Get carousel data
  const getTabData = async (micrositeId) => {
    try {
      setLoading(true);
      const response = await getRequest(
        userapiservice.getMicrositeDetailsTabs(micrositeId, 'corousel')
      );
      if (response) {
        setCarouselData(response?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
      }
    } catch (error) {
      toast.error(error.error);
    } finally {
      setLoading(false);
    }
  };

  const getSiteSetting = async () => {
    try {
      setLoading(true);
      if (microSiteId) {
        const { settings: response } = await getRequest(
          userapiservice.getsettings(microSiteId, partnerId)
        );
        if (response) {
          if (age > 18) {
            setSiteSitting(response);
          } else {
            let tempObj = {
              ...response,
              // vacancies: true,
              // contract: true,
            };
            setSiteSitting(tempObj);
          }
        }
      }
    } catch (error) {
      toast.error(error.error);
    } finally {
      setLoading(false);
    }
  };

  const getBookmark = async () => {
    try {
      const response = await getRequest(
        userapiservice.getBookmark(phoneNumber, partner)
      );
      if (response) {
        setAllBookmarkedSites(response?.microsites
          .map((microsite) => microsite?.id))
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const postBookmark = async () => {
    try {
      const response = await postRequest(
        userapiservice.postBookmark(microSiteId, phoneNumber)
      );
      if (response) {
        toast.success("Added to favorites");
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const removeBookmark = async () => {
    try {
      const response = await deleteRequest(
        userapiservice.removeBookmark(microSiteId, phoneNumber)
      );

      if (response) {
        toast.success("Removed from favorites");
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const handleBookmark = () => {
    if (!bookmarked) {
      if (accessToken) {
        postBookmark();
        setBookmarked(true);
      }
      else {
        toast.error("Please login to favorite microsite.")
      }
    } else {
      removeBookmark();
      setBookmarked(false);
    }
  };

  const OperationsSlot = {
    left: (
      <div className="cursor-pointer" onClick={() => { onClickArrow("left", filteredTabs) }}>
        <TabLeftArrow />
      </div>
    ),
    right: (
      <div className="cursor-pointer" onClick={() => onClickArrow("right", filteredTabs)}>
        <TabRightArrow />
      </div>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position, activeKey, siteSetting, filteredTabs]);


  useEffect(() => {
    let tabVal = localStorage.getItem("tabValue");

    const previousActiveTabExists = filteredTabs.some(tab => tab.tabKey === tabVal)

    if (filteredTabs && tabVal && previousActiveTabExists) {
      setActiveKey(tabVal);
    }
    getMicrositeDetails();

  }, [filteredTabs]);

  useEffect(() => {
    if (microSiteId) {
      allBookmarkedSites.map((id) => {
        if (id === microSiteId) {
          setBookmarked(true)
        }
      })
    }
  }, [allBookmarkedSites, microSiteId])


  useEffect(() => {
    if (phoneNumber && partner) {
      getBookmark();
    }
  }, [phoneNumber, partner])

  useEffect(() => {
    if (microSiteId) {
      getSiteSetting();
      getMicrositeLogo();
      getTabData(microSiteId);
    }
  }, [microSiteId]);

  useEffect(() => {

    if (siteSetting) {
      let localFilteredTabs = allTabsList.filter((tab) => {
        return tab.default || siteSetting[tab.settingsKey];
      });
      setFilteredTabs(localFilteredTabs);
    }
  }, [siteSetting]);


  const onClickArrow = (direction, filteredTabs) => {

    const getIndex = filteredTabs.findIndex((d) => d.tabKey == activeKey);
    if (getIndex !== -1) {
      if (direction === "right" && filteredTabs[getIndex + 1].tabKey) {
        changeTab(filteredTabs[getIndex + 1].tabKey)
      } else if (
        direction === "left" &&
        filteredTabs[getIndex - 1].tabKey
      ) {
        changeTab(filteredTabs[getIndex - 1].tabKey)
      }
    }
  };

  // set microsite logo url in localstorage to use in microsite details page after reload
  useEffect(() => {
    localStorage.setItem("micrositeLogo", reduxGlobalState?.micrositeDetails?.micrositeLogo)
    localStorage.setItem("micrositeName", reduxGlobalState?.micrositeDetails?.micrositeName)
  }, [reduxGlobalState.micrositeDetails])


  return (
    <div className={`microsite-container ${isShared ? 'h-100' : ''} `}>
      {!loading && filteredTabs?.length > 0 ?
        <motion.div
          className="microsite-background-container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >



          <div className='microsite-body-container'>
            <div className="microsite-title-bar-container" >
              {!isShared && <span className="microsite-back-btn" onClick={() => { navigate(-1); dispatch(renderList(true)) }} ><Back /></span>}
              <div className='microsite-navbar'>
                <span className="microsite-brand">
                  {reduxGlobalState?.micrositeDetails?.micrositeLogo &&
                    < img src={reduxGlobalState?.micrositeDetails?.micrositeLogo} className="img-fluid" alt="" />}
                </span >
                <strong className="microsite-brand-title" >
                  {reduxGlobalState?.micrositeDetails?.micrositeName}
                </strong>
              </div>
              {!isShared &&
                <span className="microsite-bookmark" onClick={handleBookmark}>
                  {bookmarked ? <FavoriteActive /> : <Favorite />}
                </span>}
            </div>

            <div className="carousel-container   bg-white">
              {carouselData && <PartnerCarousel carouselData={carouselData} />}
            </div>

            {<div className="tabs-container">
              {filteredTabs && (
                <Tabs
                  onChange={changeTab}
                  activeKey={activeKey}
                  defaultActiveKey={TAB_KEYS.aboutUs}
                  tabBarExtraContent={slot}
                  className="microsite-tabs"
                  popupClassName="tabs-dropdown"
                  type="card"
                  tabBarGutter={3}
                  destroyInactiveTabPane // will cause dropdown to glitch
                  items={filteredTabs.map((tab) => {
                    return {
                      label: tab?.tabName || '',
                      key: tab?.tabKey || '',
                      children:
                        <div className={`tab-content-wrapper ${tab?.hasSubTabs ? 'h-100' : ''}`}>
                          {tab?.component ? tab?.component
                            :
                            <div className='tab-body-container' >
                              {tab?.tabName}
                            </div>}
                        </div>
                      ,
                    };
                  })}
                />
              )}
            </div>}
          </div>
        </motion.div> :

        <div className={`microsite-container overflow-hidden h-100  d-flex justify-content-center `}>
          <div className="microsite-background-container h-100  d-flex justify-content-center " >
            <div className='microsite-body-container w-100 d-flex justify-content-center '>
              {/* <div className="h-100"> */}
              {!micrositeNotFound ? <Loader /> : <PageNotFound
                message={`${isShared ? "Oops...!  The microsite you're looking for does not exist please contact administrator"
                  :
                  "Oops...!  The microsite you're looking for does not exist"}`}
                showLinks={isShared ? false : true}
                links={[
                  { message: 'View list of', title: 'Microsites', link: "/microsite" }
                ]} />}
              {/* </div> */}
            </div>
          </div>
        </div>}
    </div>
  )
}

export default NewMicrosite;
