import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Tag, Spin } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import "./experienceOfWork.scss";
import CardList from "./newUx/CardList/CardList";

export default function ExperienceOfWork({
  ChangeShowDetailHandler,
  changeTab,
}) {
  const [loading, setLoading] = useState(true);
  const [selectedOption, setselectOption] = useState({});
  const [data, setData] = useState([{}]);
  const [videoCompleted, setVideoCompleted] = useState(false);
  const [activeKey, setActiveKey] = useState("work-experience");
  const [responseData, setReponseData] = useState([]);
  const [mediatype, setmediatype] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const color = [
    "#E75E0D",
    "#EB9F11",
    "#ABC710",
    "#009340",
    "#CC0370",
    "#8366A9",
    "#149BD7",
  ];


  const getExperienceOfWork = async () => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      if (id) {
        const response = await getRequest(
          userapiservice.getExperienceOfWork(id)
        );
        setReponseData(response);

        setLoading(false);
      }
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getExperienceOfWork(activeKey, id);
  }, [activeKey, id]);

  // console.log("eow", responseData)

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const PlayVimeoVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  // const onCardClickHandler = (data, id) => {
  //   data?.experienceOfWorkOpportunityOutput?.description
  //     ? 
  //     navigate(`/microsite/${data.id}/micrositeDetails`, {
  //         state: { page: activeKey, data: data },
  //       })
  //     : localStorage.setItem("contact-tabValue", "EXPERIENCES_OF_WORK");
  //   if (data.experienceOfWorkOpportunityOutput?.media) {
  //     const payload = {};
  //     payload["description"] =
  //       data.experienceOfWorkOpportunityOutput.description;
  //     payload["title"] = data.experienceOfWorkTitle;
  //     payload["mediaLink"] = {};
  //     payload["mediaLink"]["mediaType"] =
  //       data.experienceOfWorkOpportunityOutput.media.mediaType;
  //     payload["mediaLink"]["url"] =
  //       data.experienceOfWorkOpportunityOutput.media.url;
  //     navigate(`/microsite/${data.id}/micrositeDetails`, {
  //       state: { page: activeKey, data: payload },
  //     });
  //   } else {
  //     changeTab("EXPERIENCES_OF_WORK");
  //   }
  // };

  const onCardClickHandler = (data, id) => {
    if (data?.experienceOfWorkOpportunityOutput?.description) {
        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: { page: activeKey, data: data },
        });
        localStorage.setItem("tabValue", "work-experience");
    } else {
        localStorage.setItem("contact-tabValue", "EXPERIENCES_OF_WORK");
        changeTab("EXPERIENCES_OF_WORK");
    }

    if (data?.experienceOfWorkOpportunityOutput?.media) {
        const payload = {
            description: data.experienceOfWorkOpportunityOutput.description,
            title: data.experienceOfWorkTitle,
            mediaLink: {
                mediaType: data.experienceOfWorkOpportunityOutput.media.mediaType,
                url: data.experienceOfWorkOpportunityOutput.media.url,
            },
        };

        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: { page: activeKey, data: payload },
        });
    } else {
        // changeTab("EXPERIENCES_OF_WORK");
    }
};


  const handleOk = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  if (loading) {
    return (
      <div
      className="ant-tabs-content-holder"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="loader">
        <Spin tip="Loading" size="large" />
      </div>
    </div>
    );
  } else {
    return (      
        <CardList
        responseData={responseData}
        color={color}
        PlayVideoHandler={PlayVideoHandler}
        convertToStandardURL={convertToStandardURL}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        videoUrl={videoUrl}
        mediaType={mediatype}
        onCardClickHandler={onCardClickHandler}
      />
    );
  }
}
