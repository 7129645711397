import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Card, Tag, Button, Row, Col } from "antd";
import { motion } from "framer-motion";
// import { LinkOutlined } from "@ant-design/icons";
import Linkedin from "../../../../assets/images/linkedIn.svg";
import mail from "../../../../assets/images/email.svg";
// import callIcon from "../../../../assets/images/callIcon2.svg";
import callIcon from '../../../../assets/newImages/callIcon.svg'
import LinkOutlined from '../../../../assets/newImages/LinkOutlined.svg'
import Wysisyg from "../../Wysisyg";
import './InfoCard.scss';

const InfoCard = ({ state = {}, handleCardTitle, jobTypeTitle, disable, phoneHandler, LinkHandler }) => {

  const isSmallScreen = useMediaQuery({ minWidth: 320, maxWidth: 767 });

  return (
    <motion.div
      className="microsite-info-card-container  spl-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {console.log("wtIsState", state)}
      <div className="site-card-border-less-wrapper">
        <Card
          style={{
            // boxShadow:"0px 4px 12px rgba(0, 0, 0, 0.25)",
          }}
          // title={handleCardTitle({ state })}
          bordered={state?.page === "ACCREDITATIONS_AWARDS" ? false : true}
        >
          {state?.page === "ACCREDITATIONS_AWARDS" &&
            <h6 className="title-header">
              {"Award"}
            </h6>
          }

          {state?.page === "news_tab" ? (
            <Wysisyg state={state} />
          ) : (
            <>
              <p>
                {(state?.data?.title ||
                  state?.data?.subTitle ||
                  state?.data?.experienceOfWorkTitle) && (
                    <h6 className="title-header">
                      {console.log("state?.data?.title", state?.data?.title)}
                      {(() => {
                        const transformTitle = (title) => {
                          if (title === "WhyDo") {
                            return "Why do we do what we do ?";
                          }
                          if (title === "whatDo") {
                            return "What do we do ?";
                          }
                          if (title === "howDo") {
                            return "How do we do what we do ?";
                          }
                          return title;
                        };

                        return (
                          transformTitle(state?.data?.title) ??
                          state?.data?.subTitle ??
                          state?.data?.experienceOfWorkTitle
                        );
                      })()}
                    </h6>
                  )}
              </p>
              <p>
                {(state?.data?.description ||
                  state?.data?.experienceOfWorkOpportunityOutput
                    ?.description) && (
                    <div
                      // style={{
                      //   textIndent: "60px",
                      //   whiteSpace: "pre-wrap",
                      //   margin: "0",
                      // }}
                      className="micro-descrp"
                      style={{
                       
                      }}
                    >
                      {state?.data?.description ??
                        state?.data?.experienceOfWorkOpportunityOutput
                          ?.description}
                    </div>
                  )}
              </p>
            </>
          )}

          {state?.page === "apprenticeship" || state?.page === "vacancy" ? (
            <div>
              <div className="type pointers">
                <p className="pointer-headers">
                  {state?.page === "apprenticeship"
                    ? "Apprenticeship type:"
                    : "Vacancy type:"}
                </p>
                <p>
                  {state?.data?.apprenticeshipType === "PART_TYPE"
                    ? "Part Time"
                    : "Full Time"}
                </p>
              </div>

              {state?.data?.jobTypeEnum &&
                jobTypeTitle(state?.data?.jobTypeEnum) && (
                  <div className="type pointers">
                    <p className="pointer-headers">Job type:</p>
                    <p>{jobTypeTitle(state?.data?.jobTypeEnum)}</p>
                  </div>
                )}

              <div className="type pointers">
                <p className="pointer-headers">Compensation:</p>
                <p>{state?.data?.compensation}</p>
              </div>

              <div className="type pointers">
                <p className="pointer-headers">Compensation type:</p>
                <p>
                  {state?.data?.compensationType === "ANNUALLY"
                    ? "Annually"
                    : "Annually"}
                </p>
              </div>

              <div className="type pointers">
                <p className="pointer-headers">Closing date:</p>
                <p>{state?.data?.closingDate}</p>
              </div>

              <div
                className="type skills"
                style={{ display: "flex", alignItems: "center" }}
              >
                <p className="skillsLabel pointer-headers">Skills:</p>
                <div className="skillTags">
                  {state?.data?.skills.map((skill) => (
                    <Tag color="red" key={skill.name}>
                      {skill.name}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          {state?.page === "contract" && (
            <div>
              {state?.data?.procurementReference && (
                <div className="type pointers">
                  <p className="pointer-headers">Procurement Reference:</p>
                  <p>{state?.data?.procurementReference}</p>
                </div>
              )}
              {state?.data?.postalCode && (
                <div className="type pointers">
                  <p className="pointer-headers">Postal code:</p>
                  <p>{state?.data?.postalCode}</p>
                </div>
              )}
              {state?.data?.valueOfContract !== 0 && (
                <div className="type pointers">
                  <p className="pointer-headers">Value Of Contract:</p>
                  <p>{state?.data?.valueOfContract}</p>
                </div>
              )}
              {state?.data?.startDate && (
                <div className="type pointers">
                  <p className="pointer-headers">Start date:</p>
                  <p>{state?.data?.startDate}</p>
                </div>
              )}
              {state?.data?.endDate && (
                <div className="type pointers">
                  <p className="pointer-headers">End date:</p>
                  <p>{state?.data?.endDate}</p>
                </div>
              )}
              {state?.data?.closeDate && (
                <div className="type pointers">
                  <p className="pointer-headers">Close date:</p>
                  <p>{state?.data?.closeDate}</p>
                </div>
              )}
              {state?.data?.closeTime && (
                <div className="type pointers">
                  <p className="pointer-headers">Close time:</p>
                  <p>{state?.data?.closeTime}</p>
                </div>
              )}
              {state?.data?.contractType && (
                <div className="type pointers">
                  <p className="pointer-headers">Contract Type:</p>
                  <p>{state?.data?.contractType}</p>
                </div>
              )}
              {state?.data?.email && (
                <div className="type pointers">
                  <p className="pointer-headers">Email:</p>
                  <p>{state?.data?.email}</p>
                </div>
              )}
            </div>
          )}

          {(state?.page === "OUR_TEAM" || state?.page === "OUR_CLIENT") &&
            state?.data?.aboutUsTeamDataOutPut?.linkedin && (
              <div style={{ width: "100%", height: isSmallScreen && "70px" }}>
                <Row
                  className="rowBody infoCard-row-detail"
                  // gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  style={{ overflow: "hidden", paddingTop: "10px" }}
                >
                  <Col className="gutter-row tabContent contactTab" span={24}>
                    <Col className="gutter-row col-style" xs={6} xl={4}>
                      <img src={Linkedin} alt="LinkedIn" />
                      <label  style={{ marginLeft: "3px" }}>LinkedIn</label>
                    </Col>
                    <Col className="gutter-row">
                      <a
                      className="anchor"
                        style={{ color: "#3067ae" }}
                        href={state?.data?.aboutUsTeamDataOutPut?.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {state?.data?.aboutUsTeamDataOutPut?.linkedin}
                      </a>
                    </Col>
                  </Col>
                </Row>
              </div>
            )}

          {(state?.page === "OUR_TEAM" || state?.page === "OUR_CLIENT") &&
            state?.data?.aboutUsTeamDataOutPut?.email && (
              <div style={{ width: "100%" }}>
                <Row
                  className="rowBody infoCard-row-detail"
                  // gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  style={{ overflow: "hidden", paddingTop: "10px" }}
                >
                  <Col className="gutter-row tabContent contactTab" span={24}>
                    <Col className="gutter-row col-style" xs={6} xl={4}>
                      <img src={mail} alt="Email" />
                      <label style={{ marginLeft: "3px" }}>Email</label>
                    </Col>
                    <Col className="gutter-row">
                      <a
                      className="anchor"
                        style={{ color: "#3067ae" }}
                        href={`mailto:${state?.data?.aboutUsTeamDataOutPut?.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {state?.data?.aboutUsTeamDataOutPut?.email}
                      </a>
                    </Col>
                  </Col>
                </Row>
              </div>
            )}

          <div style={{ display: "flex", justifyContent: "end" }}>
            {state?.page === "apprenticeship" || state?.page === "vacancy" ? (
              <div className="apply-btn">
                <a
                  href={state?.data?.urlLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {state?.data?.urlLink && (
                    <button disabled={disable} className="applyButton"
                    style={{marginTop:isSmallScreen && "10px"}}
                    >
                      Apply
                    </button>
                  )}
                </a>
              </div>
            ) : null}

            {/* {state?.page !== "about-us" && (
              <div className="phone-icon">
                <Button
                  //   disabled={disable}
                  className="call-btn"
                  onClick={phoneHandler}
                >
                  <img src={callIcon} alt="Call" />
                </Button>
              </div>
            )}

            {state?.data?.urlLink || state?.data?.webLink ? (
              <div className="phone-icon">
                <Button
                  className="call-btn"
                  // icon={<LinkOutlined />}
                  onClick={LinkHandler}
                >
                  <img src={LinkOutlined} alt="LinkOutlined" />
                </Button>
              </div>
            ) : null} */}
          </div>
        </Card>
      </div>
    </motion.div>
  );
};

export default InfoCard;