import { Button, Modal, Select, Radio } from "antd";
import { useEffect, useState,useContext } from "react";
import { Form } from "antd";
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import closeIcon from "../../assets/images/closecross.svg";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import "../editProfile/profile.scss";
import { ProfileClose } from "../../components/Icons/Icons";

// export default function Profile(id, interest,) {
export default function Profile({ closeModal }) {
  const { setProfileOpen, setOpenMicrosite } =
  useContext(GlobalContext);
  // console.log()
  const navigate = useNavigate();
  // console.log("onentry", show);
  const [form] = Form.useForm();
  const [age, setAge] = useState(
    localStorage.getItem("age") ? localStorage.getItem("age") : ""
  );
  const [prof, setProf] = useState(
    localStorage.getItem("profID") ? localStorage.getItem("profID") : ""
  );
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupData, setAgeGroupsData] = useState([]);
  const [selectedAgeGroupsData, setSelectedAgeGroupsData] = useState();
  const [firstValue, setFirstValue] = useState();

  const [showAlert, setShowAlert] = useState(false);
  const [profAlert, setProfAlert] = useState(false);
  const [isActive, setActive] = useState("false");
  const [updatedDesignation,setUpdatedDesignation] = useState("")

  const [isDropdownDisabled, setisDropdownDisabled] = useState(true);

  const handleCancel = () => {
    closeModal();
    setAge(null);
    localStorage.removeItem("age");
    localStorage.removeItem("ageID");
    localStorage.removeItem("profID");
    localStorage.removeItem("ageName");
    // console.log("onclose", open)
    // setShowAlert(false);
  };
  const handleOk = () => {
    // age ? navigate("/home") : setShowAlert(true);
    // age && prof ? navigate("/view") : setShowAlert(true);
    age && prof ? navigate("/microsite") : age && !prof ? setProfAlert(true) : setShowAlert(true)
    setProfileOpen(false);
    setOpenMicrosite(true);
  };

  const getAgeGroups = async () => {
    try {
      const response = await getRequest(userapiservice.getEndUserAgeGroups());
      // console.log("age", response);
      setAgeGroups(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeData = async (value) => {
    try {
      const response = await getRequest(userapiservice.getWhatDoYouDo(value));
      setAgeGroupsData(response);
      setFirstValue(response[0].tag.userTag);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const ageGroupDataChange = (groupData) => {
    setUpdatedDesignation(groupData);
    setSelectedAgeGroupsData(groupData);
    localStorage.setItem("prof", groupData);
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  const setLocalAge = (value, id) => {
    if (value === "< 18") {
      setAge(18);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 18);
    } else if (value === "19 - 23") {
      setAge(23);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 23);
    } else if (value === "24 - 37") {
      setAge(37);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 37);
    } else if (value === "38 - 53") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    } else if (value === "54+") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    }
  };

  useEffect(() => {
    getAgeGroups();
  }, []);

  return (
    <>
      <Modal
        closable={false}
        centered
        open={true}
        onCancel={handleCancel}
        // closeIcon={<img className="closeIcon" src={closeIcon} />}
        okText="Apply"
        className="profile-modal-container profile-modal"
        footer={[
          <button
            className="btn btn-danger profile-submit-btn"
            type="button"
            onClick={handleOk}
          >
            Search
          </button>,
        ]}
      >
        <div className="d-flex align-items-center justify-content-between title-bar text-cap " >
          <strong  >
            Create my profile
          </strong>
          <button className="btn btn-small p-0" onClick={handleCancel} > <ProfileClose style={{ width: '13px', height: '13px' }} /> </button>
        </div>
        <div
          className="radio-group-space"
          direction="vertical"
        // style={{ display: "flex" }}
        >
          <label className="">Select your age</label>

          <div className="" >
            <Radio.Group
              className="age-radio"
              // defaultValue="a"
              // defaultValue="24 - 37"
              // defaultChecked="true"
              // size="small"
              buttonStyle="solid"
              style={{
                // marginBottom: 16,
                display: "block",
                textAlign: "center",
                width: "100%",
              }}
            >
              <div className="create-age-radio overflow-hidden">
                {ageGroups && ageGroups.length > 0
                  ? ageGroups.map((ageGroup) => (
                    <Radio.Button
                      value={ageGroup?.id}
                      // type="radio"
                      onClick={() => {
                        getAgeData(ageGroup?.id);
                        setisDropdownDisabled(false);
                        localStorage.setItem("ageID", ageGroup?.id);
                        localStorage.setItem("ageName", ageGroup?.ageGroup);
                        setSelectedAgeGroupsData(null);
                        // setShowAlert(false);
                        toggleClass();
                        setLocalAge(ageGroup.ageGroup, ageGroup.id);
                      }}
                    >
                      {ageGroup?.ageGroup}
                    </Radio.Button>
                  ))
                  : ""}
              </div>
            </Radio.Group>
          </div>
          <Form>
            <Form.Item  >
              <label>What do you do?</label>
              <div className="p-2" >
                <Select
                  className="customSelect "
                  disabled={isDropdownDisabled}
                  placeholder="Enter what you do"
                  value={selectedAgeGroupsData}
                  onChange={(e) => {
                    const profID = ageGroupData?.find(
                      (element) => element.tag.userTag === e
                    );
                    setProf(profID?.tag?.id);
                    // console.log("----", profID);
                    localStorage.setItem("profID", profID?.tag?.id);
                    ageGroupDataChange(e);
                  }}
                >
                  {ageGroupData &&
                    ageGroupData?.map((ageGroupData, i) => (
                      <Select.Option
                        key={ageGroupData.tag.id}
                        value={ageGroupData.tag.userTag}
                      >
                        {ageGroupData.tag.userTag}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            </Form.Item>
          </Form>
        </div>
        {profAlert && (
          <div className="alert-container">
            <label
              className="age-alert"
              style={{ color: "red", width: "100%" }}
            >
              Please select profession!
            </label>
          </div>
        )}
        {showAlert && (
          <div className="alert-container">
            <label
              className="age-alert"
              style={{ color: "red", width: "100%" }}
            >
              Please set age & profession!
            </label>
          </div>
        )}
      </Modal>
    </>
  );
}
