// LayoutContext.js
import React, { createContext, useEffect, useState } from 'react';

export const GlobalContext = createContext();

export const MOBILE_SCREEN_THRESHOLD = 768

export const GlobalContextProvider = ({ children }) => {
    const [globalState, setGlobalState] = useState({
      isMobile: window.innerWidth < MOBILE_SCREEN_THRESHOLD,
      scale: window.devicePixelRatio,
    });
    const [profileOpen, setProfileOpen] = useState(false);
    const [openMicrosite, setOpenMicrosite] = useState(false);
    const [endUserDetails, setEndUserDetails] = useState({});
    const [profileUpdated, setProfileUpdated] = useState(false);
    console.log('globalState', globalState)

    useEffect(() => {
        const handleResize = () => {
            setGlobalState({
                ...globalState,
                isMobile: window.innerWidth < MOBILE_SCREEN_THRESHOLD,
                scale: window.devicePixelRatio
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <GlobalContext.Provider
        value={{
          globalState,
          setGlobalState,
          profileOpen,
          setProfileOpen,
          openMicrosite,
          setOpenMicrosite,
          endUserDetails,
          setEndUserDetails,
          profileUpdated, 
          setProfileUpdated
        }}
      >
        {children}
      </GlobalContext.Provider>
    );
};
