import { Button, Modal, Select, Space } from "antd";
import { Fragment, useEffect, useState } from "react";
import { Form } from "antd";
import { toast } from "react-toastify";
import { getRequest } from "../../api/http";
import { Filter as IconFilter} from "../../components/Icons/Icons";
import userapiservice from "../../api/userapiservice";
import { MinusCircleOutlined } from "@ant-design/icons";
// import closeIcon from "../../assets/images/closecross.svg";
import closeIcon from "../../assets/newImages/ProfileClose.svg";

import OrganiationSizes from "../../json/organizationSize.json";
import "./filter.scss";
// import FilterIcon from "../../assets/images/FilterIcon.svg";
import { Filter as FilterIcon } from "../../components/Icons/Icons";

function Filter({
  mask,
  selectedEvent,
  setselectedEvent,
  selectedOrganisationType,
  setSelectedOrganisationType,
  setOrganisationNameById,
  dynamicOrganisationTypes,
  setData,
  pageType,
  radiusValue,
  updateFilterData,
  children,
  title,
  className,
  style,
  icon,
  name,
}) {
  // console.log("Filter", interest);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  const [sectorValue, setSectorValue] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : ""
  );
  const [subSectorValue, setSubsectorValue] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : ""
  );
  const [sizeValue, setSizeValue] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : ""
  );
  const [searchName, setSearchName] = useState(name ? name : "");
  // const [organizationValue, setOrganizationValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const phoneNumber = localStorage.getItem("Phone Number");
  // const defaultOrganisationType = [""];
  const [organisationType, setOrganisationType] = useState([]);

  const getOnOrganisationType = async () => {
    try {
      const response = await getRequest(
        userapiservice.getOnOrganisationType(partnerId)
      );
      if (response) {
        setOrganisationType(response);
        // console.log("object", response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getOnOrganisationType();
  }, []);

  const [organisationTypeName, setOrganisationTypeName] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );
  // const [selectedOrganisationType, setSelectedOrganisationType] = useState(
  //   localStorage.getItem("OrganisationType")
  //     ? localStorage.getItem("OrganisationType")
  //     : null
  // );
  const partner = localStorage.getItem("partner");
  // const [selectedEvent, setselectedEvent] = useState(
  //   localStorage.getItem("Interest/AssetType")
  //     ? localStorage.getItem("Interest/AssetType")
  //     : null
  // );
  const [interestName, setinterestName] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );

  const interest = [
    "",
    "PRODUCT",
    "CONTRACTS",
    "SERVICE",
    "VACANCY",
    "APPRENTICESHIP",
    "WORK_EXPERIENCE",
  ];

  let sectorId = localStorage.getItem("sector");
  let subSectorId = localStorage.getItem("subsector");
  let size = localStorage.getItem("size");
  let search = localStorage.getItem("searchName");

  const sectorChange = async (sectorId, i) => {
    // console.log("sec", sectorId)
    setSectorValue(sectorId);
    if (sectorId) {
      try {
        // setSubsectorValue("");
        setLoading(true);
        // const headers = {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        // };
        const response = await getRequest(
          userapiservice.getSubSectors(sectorId)
        );
        setSubSectors(response);
        setSubsectorValue("");
        setLoading(false);
      } catch (error) {
        toast.error(error.error);
        setLoading(false);
      }
    } else {
      // console.log("empty")
      setSubsectorValue("");
      setSubSectors(null);
    }
  };

  const subSectorChange = async (subSectorIds) => {
    // console.log(subSectorIds)
    setSubsectorValue(subSectorIds);
  };

  const SizeChange = async (size) => {
    setSizeValue(size);
  };

  const partnerId = localStorage.getItem("partnerId");

  const fetchSectorList = async () => {
    try {
      const response = await getRequest(
        userapiservice.getAllSectors(partnerId)
      );
      setSectors(response);
      if (sectorId) {
        setSectorValue(sectorId);
        sectorChange(sectorId);
      }
      if (size) {
        setSizeValue(size);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const filterMicrosites = async (
    partner,
    organisationType,
    interest,
    sectorId,
    subSectorIds,
    size,
    search
  ) => {
    try {
      setConfirmLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      // console.log("in filterapi callll", interest);
      let response;
      if (pageType === "Homepage") {
        response = await getRequest(
          userapiservice.filterMicrosites(
            partner,
            organisationType,
            interest,
            sectorId,
            subSectorIds,
            size,
            search
          )
        );
        // console.log("FilterMicrosites:", response);
        setData(
          response.result,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size
        );
        // setData(response);
      }
      if (pageType === "FavoritesPage") {
        response = await getRequest(
          userapiservice.filterFavorites(
            partner,
            phoneNumber,
            organisationType,
            interest,
            sectorId,
            subSectorIds,
            size,
            search
          )
        );
        // console.log("FilterMicrosites:", response);
        setData(
          response.result,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size
        );
        // setData(response);
      }

      if (pageType === "PartnerPage") {
        response = await getRequest(
          userapiservice.filterMicrosites(
            partner,
            organisationType,
            interest,
            sectorId,
            subSectorIds,
            size,
            search
          )
        );
        // console.log("Partner FilterMicrosites:", response);
        setData(
          response.result,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size
        );
        // console.log(setData);
        // setData(response);
      }
      if (pageType === "PartnerPage") {
        response = await getRequest(
          userapiservice.filterMicrosites(
            partner,
            organisationType,
            interest,
            sectorId,
            subSectorIds,
            size,
            search
          )
        );
        // console.log("Partner FilterMicrosites:", response);
        setData(
          response.result,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size
        );
        // console.log(setData);
        // setData(response);
      }
      if (pageType === "Viewpage") {
        const response = await getRequest(
          userapiservice.filterMicrosites(
            partner,
            organisationType,
            interest,
            sectorId,
            subSectorIds,
            size,
            search
          )
        );
        setData(
          response,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size
        );
        // setData(response);
      }

      setConfirmLoading(false);
      setTimeout(() => {
        // setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    } catch (error) {
      toast.error(error.error);
      setConfirmLoading(false);
    }
  };

  const handleOk = (value) => {
    // setConfirmLoading(true);
    setOpen(false);
    // console.log("filtermicrositecall", selectedEvent);
    filterMicrosites(
      partner,
      selectedOrganisationType,
      selectedEvent,
      sectorValue,
      subSectorValue,
      sizeValue,
      // organizationValue,
      search
    );
    // console.log("interest", interest)
    // console.log("name", name);
    localStorage.setItem(
      "OrganisationType",
      selectedOrganisationType ? selectedOrganisationType : ""
    );
    localStorage.setItem(
      "Interest/AssetType",
      selectedEvent ? selectedEvent : ""
    );
    localStorage.setItem("sector", sectorValue ? sectorValue : "");
    localStorage.setItem("subsector", subSectorValue ? subSectorValue : "");
    localStorage.setItem("size", sizeValue ? sizeValue : "");
    localStorage.setItem("searchName", name ? name : "");
    localStorage.setItem('filterAvailable',true)
  };

  const handleCancel = () => {
    setOpen(false);
    localStorage.setItem('filterAvailable',false)
  };

  const handleCross = () => {
    // setSectorValue(null);
    setSubSectors([]);
    // setSubsectorValue(null);
    // setSizeValue(null);
    localStorage.removeItem("organisationType");
    localStorage.removeItem("Interest/AssetType");
    localStorage.removeItem("tabValue");
    localStorage.removeItem("sector");
    localStorage.removeItem("subsector");
    localStorage.removeItem("size");
    localStorage.setItem('filterAvailable',false)
  };

  // const organizationNameChange = async (e) => {
  //   setOrganizationValue(e.target.value);
  // };

  const onChangeOrgType = (value) => {
    setSelectedOrganisationType(value);
    const name = organisationType?.find((type) => type?.id === value)?.type;
    setOrganisationNameById(name);
    localStorage.setItem('organisationNameById',name)
  };

  const deleteSelectedValue = (variableName) => {
    if (variableName == "interest") {
      setselectedEvent(null);
      setinterestName(null);
      localStorage.removeItem("Interest/AssetType");
      localStorage.removeItem("tabValue");
      filterMicrosites(
        sectorValue,
        subSectorValue,
        sizeValue,
        organisationTypeName
      );
    }
    if (variableName == "organisationType") {
      setSelectedOrganisationType(null);
      setOrganisationNameById(null);
      setOrganisationTypeName(null);
      localStorage.removeItem("organisationType");
      localStorage.removeItem("organisationNameById")
      filterMicrosites(sectorValue, subSectorValue, sizeValue, null);
    }
    if (variableName == "sector") {
      setSectorValue(null);
      setSubSectors(null);
      setSubsectorValue(null);
      localStorage.removeItem("sector");
      localStorage.removeItem("subsector");
      filterMicrosites(null, null, sizeValue, organisationTypeName);
    }
    if (variableName == "subsector") {
      setSubsectorValue(null);
      localStorage.removeItem("subsector");
      filterMicrosites(sectorValue, null, sizeValue, organisationTypeName);
    }
    if (variableName == "size") {
      setSizeValue(null);
      localStorage.removeItem("size");
      filterMicrosites(sectorValue, subSectorValue, null, organisationTypeName);
    }
  };

  useEffect(() => {
    fetchSectorList();
  }, []);

  useEffect(() => {
    setinterestName(
      localStorage.getItem("Interest/AssetType")
        ? localStorage.getItem("Interest/AssetType")
        : ""
    );
    setOrganisationTypeName(
      localStorage.getItem("OrganisationType")
        ? localStorage.getItem("OrganisationType")
        : ""
    );
    setSectorValue(
      localStorage.getItem("sector") ? localStorage.getItem("sector") : ""
    );
    setSubsectorValue(
      localStorage.getItem("subsector") ? localStorage.getItem("subsector") : ""
    );
    setSizeValue(
      localStorage.getItem("size") ? localStorage.getItem("size") : ""
    );
    setSearchName(
      localStorage.getItem("searchName")
        ? localStorage.getItem("searchName")
        : ""
    );
  }, [updateFilterData]);

  useEffect(() => {
    // console.log("selectedOrganisationType:", selectedOrganisationType);
    form.setFieldsValue({
      organisationType: selectedOrganisationType || "All",
      interests: selectedEvent || "All",
    });
  }, [selectedOrganisationType, selectedEvent]);

  return (
    <Fragment>
      {/* <Button
        id="submit-btn"
        type="primary"
        htmlType="submit"
        className={className}
        // className="form-submit-btn"
        onClick={() => setOpen(true)}
        icon={icon}
      >
        <span style={{ paddingRight: "15%", color: "white" }}>{title}</span>
        <img className="filterIcon" src={FilterIcon} />
      </Button> */}

      <button
        className="filter-btn btn border micro-filter-btn"
        type="button"
        onClick={() => {
          console.log("clicked");
          setOpen(true);
        }}
      >
        <FilterIcon />
      </button>

      <Modal
        mask={mask}
        // title={title}
        title={
          <div className="microsite-filter-title">
            <IconFilter />
          </div>
        }
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closeIcon={
          <img
            className="closeIcon"
            style={{ width: "14px", top: "15px" }}
            src={closeIcon}
            onClick={() => handleCross()}
          />
        }
        okText="Apply"
        className="modal-container"
        style={style}
      >
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          {/* <div className="search-in-modal">
        <Input
          placeholder={`Try "aerospace companies"`}
          // onSearch={onSearch}
          onChange={(e) => organizationNameChange(e)}
          suffix={suffix}
          prefix={prefix}
        />
      </div> */}
          {/* {children} */}

          <Form form={form} className="p-0 profile-filters-form ">
            <Form.List name="sectorsOutputs">
              {(fields, i) => (
                <>
                  <div className="form-container">
                    <Form.Item name="size">
                      <label className="label-text">Organisation Type</label>
                      {/* <Select
                        placeholder="Select Option"
                        value={selectedOrganisationType || "All"}
                        onChange={(e) => onChangeOrgType(e)}
                        form={form}
                      > */}
                      <Select
                        placeholder="Select Option"
                        value={
                          organisationType?.find(
                            (type) => type.id === selectedOrganisationType
                          )?.type || "All"
                        }
                        onChange={(e) => onChangeOrgType(e)}
                        form={form}
                      >
                        {organisationType?.map((type, i) => (
                          <Select.Option key={i} value={type.id}>
                            {type.type === "" ? "All" : type.type}
                          </Select.Option>
                        ))}
                      </Select>
                      <div
                        className={`remove-btn ${
                          !selectedOrganisationType ||
                          (selectedOrganisationType &&
                            selectedOrganisationType == "")
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <MinusCircleOutlined
                          onClick={() =>
                            deleteSelectedValue("organisationType")
                          }
                        />
                      </div>
                    </Form.Item>
                  </div>
                  <div className="form-container">
                    <Form.Item>
                      <label className="label-text">Interests</label>
                      <Select
                        placeholder="Select Option"
                        value={selectedEvent || "All"}
                        onChange={(e) => {
                          localStorage.setItem(
                            "Interest/AssetType",
                            e ? e : ""
                          );
                          setselectedEvent(e);
                          if (e === "PRODUCT") {
                            localStorage.setItem("tabValue", "product");
                          } else if (e === "SERVICE") {
                            localStorage.setItem("tabValue", "service");
                          } else if (e === "CONTRACTS") {
                            localStorage.setItem("tabValue", "contract");
                          } else if (e === "VACANCY") {
                            localStorage.setItem("tabValue", "vacancy");
                          } else if (e === "APPRENTICESHIP") {
                            localStorage.setItem("tabValue", "apprenticeship");
                          } else {
                            localStorage.setItem("tabValue", "work-experience");
                          }
                        }}
                      >
                        {/* <Select.Option value="">All</Select.Option> */}
                        {interest?.map((Search, i) => (
                          <Select.Option key={i} value={interest[i]}>
                            {interest[i] === ""
                              ? "All"
                              : interest[i] === "APPRENTICESHIP"
                              ? "Apprenticeships"
                              : interest[i] === "VACANCY"
                              ? "Vacancies"
                              : interest[i] === "PRODUCT"
                              ? "Products"
                              : interest[i] === "CONTRACTS"
                              ? "Contracts"
                              : interest[i] === "SERVICE"
                              ? "Services"
                              : interest[i] === "WORK_EXPERIENCE"
                              ? "Experiences of work"
                              : ""}
                          </Select.Option>
                        ))}
                      </Select>
                      <div
                        className={`remove-btn ${
                          !selectedEvent ||
                          (selectedEvent && selectedEvent == "")
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <MinusCircleOutlined
                          onClick={() => deleteSelectedValue("interest")}
                        />
                      </div>
                    </Form.Item>
                  </div>
                  <div className="form-container">
                    <Form.Item>
                      <label className="label-text">Categories</label>
                      <Select
                        value={sectorValue}
                        onChange={(e) => sectorChange(e, i)}
                      >
                        <Select.Option value="">All</Select.Option>
                        {sectors?.map((Sector, i) => (
                          <Select.Option key={i} value={Sector.id}>
                            {Sector.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <div
                        className={`remove-btn ${
                          !sectorValue || (sectorValue && sectorValue == "")
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <MinusCircleOutlined
                          onClick={() => deleteSelectedValue("sector")}
                        />
                      </div>
                    </Form.Item>
                  </div>
                  <div className="form-container">
                    <Form.Item name={["subSectorOutput", "name"]}>
                      <label className="label-text">Sub Categories</label>
                      <Select
                        value={subSectorValue}
                        onChange={(e) => subSectorChange(e, i)}
                      >
                        <Select.Option value="">All</Select.Option>
                        {subSectors &&
                          subSectors?.map((subSector, i) => (
                            <Select.Option key={i} value={subSector.id}>
                              {subSector.name}
                            </Select.Option>
                          ))}
                      </Select>
                      <div
                        className={`remove-btn ${
                          !subSectorValue ||
                          (subSectorValue && subSectorValue == "")
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <MinusCircleOutlined
                          onClick={() => deleteSelectedValue("subsector")}
                        />
                      </div>
                    </Form.Item>
                  </div>
                  <div className="form-container">
                    <Form.Item name="size">
                      <label className="label-text">Size by employees</label>
                      <Select
                        value={sizeValue}
                        onChange={(e) => SizeChange(e, i)}
                      >
                        <Select.Option value="">All</Select.Option>
                        {OrganiationSizes.map((OrganiationSize, i) => (
                          <Select.Option key={i} value={OrganiationSize.id}>
                            {OrganiationSize.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <div
                        className={`remove-btn ${
                          !sizeValue || (sizeValue && sizeValue == "")
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <MinusCircleOutlined
                          onClick={() => deleteSelectedValue("size")}
                        />
                      </div>
                    </Form.Item>
                  </div>
                </>
              )}
            </Form.List>
          </Form>
        </Space>
      </Modal>
    </Fragment>
  );
}

export default Filter;
