import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getRequest } from "../../../../api/http";
import userapiservice from "../../../../api/userapiservice";

import Loader from "../../../../components/Loader/Loader";
import CardList from "../CardList/CardList";
import "./ourPurpose.scss";

export default function OurPurpose({ activeKey }) {
    const [loading, setLoading] = useState(true);
    const [responseData, setResponseData] = useState([]);
    const id = localStorage.getItem("micrositeID");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [mediaType, setMediaType] = useState("");
    const navigate = useNavigate();

    const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];

    const getAboutData = async (activeKey, id) => {
        try {
            setLoading(true);
            if (id) {
                const response = await getRequest(
                    userapiservice.getAboutUsDetailsTabs(id, activeKey)
                );
                console.log('res', response)
                setResponseData(response);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeKey && id) {
            getAboutData(activeKey, id);
        }
    }, []);

    const PlayVideoHandler = (url, mediaType) => {
        setMediaType(mediaType)
        setVideoUrl(url);
        setIsModalOpen(true);
    };

    const convertToStandardURL = (shortUrl) => {
        if (shortUrl?.indexOf('youtu.be') !== -1) {
            const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
            return `https://www.youtube.com/watch?v=${video_id}`;
        }
        return shortUrl;
    }


    const handleCancel = () => {
        setIsModalOpen(false);
        setVideoUrl("");
    };

    const onCardClickHandler = (data) => {
        console.log('activeKey',activeKey,data)
        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: { page: activeKey, data: data },
        });
    };

    if (loading) {
        return (
            <Loader />
        )
    } else {
        return (
            <CardList
                responseData={responseData}
                color={color}
                PlayVideoHandler={PlayVideoHandler}
                convertToStandardURL={convertToStandardURL}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                videoUrl={videoUrl}
                mediaType={mediaType}
                onCardClickHandler={onCardClickHandler}
            />
        );
    }
}
