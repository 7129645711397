import { motion } from "framer-motion";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import Loader from "../../../../components/Loader/Loader";
import "./micrositeDetailsMedia.scss";

const MicrositeDetailsMedia = ({ data, onLoadComplete }) => {
  const [isLoading, setIsLoading] = useState(true);

  if (
    (data?.mediaLink?.mediaType === "SOCIAL_MEDIA" ||
      data?.mediaLink?.mediaType === "VIMEO_MEDIA") &&
    data?.mediaLink?.url
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className="media-container"
        style={{ paddingBottom: "0px" }}
      >
        <div className="slide">
          {isLoading && <Loader />}
          <ReactPlayer
            className="video-player"
            url={data?.mediaLink?.url}
            controls={true}
            height="100%"
            width="100%"
            config={{
              youtube: {
                playerVars: {
                  fs: 1,
                  showinfo: 0,
                  modestbranding: 0,
                  showinfo: 1,
                  allowfullscreen: true,
                },
              },
              vimeo: {
                playerOptions: {
                  title: 1,
                  byline: 1,
                  portrait: 1,
                  dnt: 1,
                },
              },
            }}
            onReady={() => {
              setIsLoading(false);
              onLoadComplete();
            }}
            style={{ visibility: `${isLoading ? "hidden" : "visible"}` }}
          />
        </div>
      </motion.div>
    );
  } else if (
    data?.mediaLink?.mediaType === "IMAGE" &&
    data?.mediaLink?.url
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className="media-container"
        style={{ paddingBottom: "0px" }}
      >
        <div className="slide">
          {isLoading && <Loader />}
          <img
            src={data?.mediaLink?.url}
            alt="Media"
            className="carousel-image"
            onLoad={() => {
              setIsLoading(false);
              onLoadComplete();
            }}
            style={{
              visibility: `${isLoading ? "hidden" : "visible"}`,
              backgroundColor: "white",
            }}
          />
        </div>
      </motion.div>
    );
  } else if (
    data?.mediaLink?.mediaType === "DEFAULT" &&
    data?.mediaLink?.url
  ) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className="media-container"
        style={{ paddingBottom: "0px" }}
      >
        <div className="slide">
          {isLoading && <Loader />}
          <img
            src={data?.mediaLink?.url}
            alt="Default Media"
            className="carousel-image"
            onLoad={() => {
              setIsLoading(false);
              onLoadComplete();
            }}
            style={{
              visibility: `${isLoading ? "hidden" : "visible"}`,
              backgroundColor: "lightgray",
              border: "1px solid #ccc",
            }}
          />
        </div>
      </motion.div>
    );
  } else {
    return null;
  }
};

export default MicrositeDetailsMedia;
