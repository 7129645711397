import { createSlice } from "@reduxjs/toolkit";

const globalSlice = createSlice({
  name: "global",
  initialState: {
    isMobile: "mobile",
    partner: null,
    userProfile: null,
    micrositeDetails: { micrositeLogo: null, micrositeName: null, },
    isShared: null,
    list:false
  },

  reducers: {
    checkIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setPartnerData: (state, action) => {
      state.partner = action.payload
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload
    },
    setMicrositeDetails: (state, action) => {
      state.micrositeDetails = action.payload
    },
    setIsShared: (state, action) => {
      state.isShared = action.payload
    },
    renderList:(state,action)=>{
state.list=action.payload
    }

  },
});

export const { checkIsMobile, setPartnerData, setUserProfile, setMicrositeDetails, setIsShared,renderList } = globalSlice.actions;
export default globalSlice.reducer
