import React, { useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";
import ytPlayBtn from '../../../../assets/newImages/Play.svg';
import ModalPlayer from '../../../../components/PlayerModal/playerModal';
import DefaultThumbnail_16X9 from '../../../../assets/newImages/16X9.svg';
import DefaultThumbnail_1X1 from '../../../../assets/newImages/1X1.svg';
import { formatDate, getPlainTextFromWYSIWYG } from "../../../../utils/helper"
import './CardList.scss';
import { Calendar } from '../../../../components/Icons/Icons';

const CardList = ({
  responseData,
  color,
  PlayVideoHandler,
  convertToStandardURL,
  isModalOpen,
  handleCancel,
  videoUrl,
  mediaType,
  onCardClickHandler,
  levels,
  levelIndexMap,
  levelColor
}) => {

  const isSmallScreen = useMediaQuery({ minWidth: 320, maxWidth: 767 });

  const level = levels?.find((level) =>
    responseData.some((data) => data.levelId === level.id)
  );

  const getLevelColor = (levelId) => {
    const level = levels.find((level) => level.id === levelId);
    return level ? levelColor[levelIndexMap[level.value]] : "transparent";
  };

  function replaceUnderscoreWithSpace(str) {
    return str?.replace(/_/g, ' ')?.toLowerCase();
  }

  const renderDescription = (data) => {
    if (data?.type) {
      switch (data.type) {
        case "PRESS":
        case "NEWS_TAB":
          return (
            <>
              <p className="news-card-description">
                {getPlainTextFromWYSIWYG(data.description)}
              </p>
              <span className="news-card-date">
                <Calendar />
                {formatDate(data.createdAt)}
              </span>
            </>
          );
        default:
          return (
            <p
              className={`card-description ${data?.experienceOfWorkOpportunityOutput?.opportunityType
                ? "experience-of-work"
                : ""
                }`}
            >
              {data.description ||
                replaceUnderscoreWithSpace(data?.experienceOfWorkOpportunityOutput?.opportunityType)}
            </p>
          );
      }
    }
    return (
      <>
        <p
          className={`card-description ${data?.experienceOfWorkOpportunityOutput?.opportunityType
            ? "experience-of-work"
            : ""
            }`}
        >
          {data.description ||
            replaceUnderscoreWithSpace(data?.experienceOfWorkOpportunityOutput.opportunityType)}
        </p>
        <p className={"card-description"} style={{ marginTop: "10px" }}>
          {data?.experienceOfWorkOpportunityOutput?.description &&
            data?.experienceOfWorkOpportunityOutput?.description}
        </p>
      </>
    );
  };

  return (
    <div className="card-list-container">
      <div className="card-container">
        {responseData && responseData.length > 0 ? (
          responseData.map((data, i) => (
            <motion.div
              key={i}
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
              className="card-item"
            >
              <div
                className="text-container"
                onClick={() => {
                  onCardClickHandler(data);
                }}
              >
                <div
                  className="card-title"
                  style={{
                    display: level && "flex",
                    alignItems: level && "center",
                    marginLeft: level ? "-8px" : "0px",
                    WebkitLineClamp: data.type === "PRESS" ? "2" : "1",
                  }}
                >
                  {level && (
                    <div
                      style={{
                        width: isSmallScreen ? "10px" : "15px",
                        height: isSmallScreen ? "10px" : "15px",
                        borderRadius: "50%",
                        backgroundColor:
                          getLevelColor(data.levelId) || "transparent",
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                    ></div>
                  )}
                  {data.title === "WhyDo"
                    ? "Why do we do what we do?"
                    : data.title === "whatDo"
                      ? "What do we do?"
                      : data.title === "howDo"
                        ? "How do we do what we do?"
                        : data?.title ||
                        data?.subTitle ||
                        data.experienceOfWorkTitle ||
                        "Story"}
                </div>
                <>
                  {renderDescription(data)}
                </>
              </div>
              <div className="image-container">
                {(data.mediaLink = (data.mediaLink &&
                  data.mediaLink.url === undefined
                  ? { url: DefaultThumbnail_16X9, mediaType: "DEFAULT" }
                  : data.mediaLink) || {
                  url: DefaultThumbnail_16X9,
                  mediaType: "DEFAULT",
                }) &&
                  (data.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                    data.mediaLink?.url ? (
                    (() => {
                      const standardURL = convertToStandardURL(
                        data.mediaLink.url
                      );
                      const videoID = standardURL.split("v=")[1]?.split("&")[0];
                      return (
                        <div
                          className="media youtube"
                          onClick={() =>
                            PlayVideoHandler(
                              data.mediaLink?.url,
                              data.mediaLink?.mediaType
                            )
                          }
                        >
                          <div style={{ position: "relative" }}>
                            <img
                              src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                              alt=""
                              className="yt-video-preview-image video-preview-image"
                            />
                            <img
                              className="ytBtnOverlay"
                              src={ytPlayBtn}
                              alt="Play Button"
                            />
                          </div>
                        </div>
                      );
                    })()
                  ) : data.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                    data.mediaLink?.url ? (
                    <div
                      className="media vimeo youtube"
                      onClick={() =>
                        PlayVideoHandler(
                          data.mediaLink?.url,
                          data.mediaLink?.mediaType
                        )
                      }
                    >
                      <img
                        src={`https://vumbnail.com/${data.mediaLink?.url?.split("/")[4]
                          }.jpg`}
                        className="vimeo-video-preview-image video-preview-image"
                        alt="Vimeo Preview"
                      />
                      <img
                        className="ytBtnOverlay"
                        src={ytPlayBtn}
                        alt="Play Button"
                      />
                    </div>
                  ) : (
                    <div
                      className="media"
                      onClick={() => {
                        if (data?.webLink) {
                          window.open(data?.webLink, "_blank");
                        }
                      }}
                    >
                      <div className="slider-Img">
                        <img
                          src={data.mediaLink?.url}
                          alt="Preview"
                          className="home-preview-img"
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className="color-band"
                style={{ backgroundColor: color[i % color?.length] }}
              />
            </motion.div>
          ))
        ) : (
          <div className="info-container">
            Nothing here at the moment!
          </div>
        )}
      </div>
      {isModalOpen ? (
        <ModalPlayer
          closeModal={handleCancel}
          mediaLink={videoUrl}
          mediaType={mediaType}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CardList;
