import { motion } from "framer-motion";
import React, { useEffect, useState, useContext, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./partnerSlider.scss";
import { Next, Previous } from "../../components/Icons/Icons";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import ReactPlayer from "react-player";
import youTubePlayButton from "../../assets/newImages/Play.svg";

export default function PartnerCarousel(props) {
  const [arrows, setArrows] = useState(true);
  const [hideTitle, setHideTitle] = useState({});
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const slider = React.useRef(null);

  const [playingVideo, setPlayingVideo] = useState('');


  const handlePlay = (id) => {
    setPlayingVideo(id);
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <span
        style={{ ...style }}
        className={className}
        onClick={onClick}
        role="button"
      >
        <Next />
      </span>
    );
  }
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <span
        style={{ ...style }}
        className={className}
        onClick={onClick}
        role="button"
      >
        <Previous />
      </span>
    );
  }

  useEffect(() => {
    let playPauseBtnList = {};
    props.carouselData.forEach((element) => {
      playPauseBtnList[element.id] = false;
    });
    setHideTitle(playPauseBtnList);
  }, [props.carouselData]);



  const settings = {
    infinite: true,
    slidesToShow:
      window.innerWidth >= 2560
        ? props?.carouselData?.length > 4
          ? 4
          : props?.carouselData?.length
        : props?.carouselData?.length > 3
          ? 3
          : props?.carouselData?.length,
    slideToScroll: 3,
    autoplay: false,
    autoplaySpeed: 0,
    dots: false,
    swipeToSlide: true,
    swipe: arrows,
    speed: 500,
    // autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "slider variable-width",
    responsive: [
      {
        //  below 450px show single card
        breakpoint: 450,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          speed: 500,
        },
      },
      {
        breakpoint: 730,
        settings: {
          centerMode: true,
          slidesToShow: props?.carouselData?.length > 2 ? 2 : 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          speed: 500,
        },
      },
    ],
  };

  function getVideoId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const handleClick = (data) => {
    setHideTitle((prevHideTitle) => ({
      ...prevHideTitle,
      [data.id]: !prevHideTitle[data.id],
    }));

    const iframe = document.getElementById(`iframe-${data.id}`);
    console.log("iframe", iframe);
  };




  const getYouTubeVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/[^\/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match ? match[1] : "";
  };

  const renderMedia = (mediaLink, index, type) => {
    if (
      mediaLink.mediaType === "SOCIAL_MEDIA" ||
      mediaLink.mediaType === "VIMEO_MEDIA"
    ) {
      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "640px",
            margin: "auto",
          }}
        >
          <ReactPlayer
            url={mediaLink.url}
            playing={mediaLink?.id === playingVideo}
            light={`${type === "vimeo"
              ? `https://vumbnail.com/${mediaLink.url?.split("/")[4]}.jpg`
              : `https://img.youtube.com/vi/${getVideoId(
                mediaLink?.url
              )}/hqdefault.jpg`
              } `}
            playIcon={
              <div
                onClick={() => { handlePlay(mediaLink?.id) }} // Ensure the play button updates the state
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 2,
                  cursor: "pointer",
                }}
              >
                <img
                  style={{
                    minHeight: "auto",
                    aspectRatio: "16 / 9"
                  }}
                  width={"auto"}
                  height={"auto"}
                  className="play-button"
                  src={youTubePlayButton}
                />
              </div>
            }
            width="100%"
            height="100%"
            controls
            style={{
              aspectRatio: "16 / 9"
            }}
          />
        </div>
      );
    }
    if (mediaLink.mediaType === "IMAGE") {
      return (
        <img
          src={mediaLink.url}
          alt="carousel item"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      );
    }
    return null;
  };

  return (
    <motion.div
      className="h-100"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <div className="partner-slider-container ">
        {!globalState.isMobile && props?.carouselData && props?.carouselData?.length > 3 && (
          <PrevArrow
            onClick={() => { slider?.current?.slickPrev(); setPlayingVideo('') }}
            className="cursor-pointer previous-arrow"
          />
        )}

        <Slider ref={slider} {...settings}>
          {props.carouselData &&
            props.carouselData.map((data, i) => {
              if (
                data?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                data.mediaLink.url
              ) {
                return (
                  <>
                    <div className="slide " key={data.id}>
                      <div
                        style={{
                          width: "100%",
                          height: "500px",
                          overflow: "hidden",
                          // border: "1px solid #ccc",
                        }}
                      >
                        {renderMedia(data?.mediaLink, i, "youtube")}
                      </div>
                      <span className="slide-title-hidden"> {data.title}</span>
                    </div>
                    <span className="slide-title "> {data.title}</span>
                  </>
                );
              } else if (
                data?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                data.mediaLink.url
              ) {
                return (
                  <>
                    <div
                      className="slide"
                      key={data.id}
                      onClick={() => {
                        handleClick(data);
                      }}
                    >
                      {renderMedia(data?.mediaLink, i, "vimeo")}
                      <span className="slide-title-hidden"> {data.title}</span>
                    </div>
                    <span className="slide-title "> {data.title}</span>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="slide " key={data.id}>
                      <img
                        src={data?.mediaLink?.url}
                        alt=""
                        className="carousel-image "
                      />
                      <span className="slide-title-hidden"> {data.title}</span>
                    </div>
                    <span className="slide-title "> {data.title}</span>
                  </>
                );
              }
            })}
        </Slider>

        {!globalState.isMobile && props?.carouselData && props?.carouselData?.length > 3 && (
          <NextArrow
            onClick={() => { slider?.current?.slickNext(); setPlayingVideo('') }}
            className="cursor-pointer next-arrow"
          />
        )}
      </div>
    </motion.div>
  );
}
