import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import Loader from "../../components/Loader/Loader";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import SubTabsComponent from "./newUx/subTabs/SubTabs";

export default function Courses() {
  const [activeKey, setActiveKey] = useState("courses");
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [responseData, setResponseData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];


  const getData = async (activeKey, id) => {
    try {
      if (id) {
        const response = await getRequest(
          userapiservice.getMicrositeDetailsTabs(id, activeKey)
        );
        setResponseData(response);
        setLoading(false);
      }
    } catch (error) { }
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf('youtu.be') !== -1) {
      const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  }


  const transformedData = responseData?.reduce((acc, item) => {
    const teamEntry = acc.find((entry) => entry.assetTitle === item?.assetTitle);
    const itemCopy = {
      id: item?.id,
      assetTitle: item?.assetTitle,
      subTitle: item?.subTitle,
      available: item?.available,
      description: item?.description,
      type: item?.type,
      mediaLink: {
        id: item?.mediaLink?.id,
        mediaType: item?.mediaLink?.mediaType,
        url: item?.mediaLink?.url,
      },
      urlLink: item?.urlLink,
      micrositeLogo: item?.micrositeLogo,
    };

    if (teamEntry) {
      teamEntry.subTitles.push(itemCopy);
    } else {
      acc.push({
        assetTitle: item?.assetTitle,
        subTitles: [itemCopy],
      });
    }

    return acc;
  }, []);

  useEffect(() => {
    getData(activeKey, id);
  }, [activeKey, id]);

  const PlayVideoHandler = (url, mediaType) => {
    setMediaType(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const PlayVimeoVideoHandler = (url, mediaType) => {
    setMediaType(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const onCardClickHandler = (data, id) => {
    navigate(`/microsite/${data.id}/micrositeDetails`, {
      state: { page: activeKey, data: data },
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  if (loading) {
    return (
      <Loader />
    )
  } else {
    return (
      <div
        className="background-container"  >
        {activeKey === "courses" &&
          responseData &&
          responseData.length > 0 ? (
          <SubTabsComponent
            data={transformedData}
            PlayVideoHandler={PlayVideoHandler}
            color={color}
            convertToStandardURL={convertToStandardURL}
            handleCancel={handleCancel}
            isModalOpen={isModalOpen}
            mediatype={mediaType}
            onCardClickHandler={onCardClickHandler}
            videoUrl={videoUrl}
          />
        ) : (
          <div className="background-container" style={{ width: "100%" }}>
            <div className="login-wall">
              <div className="info-container">
                <span>Nothing here at the moment</span>
              </div>
            </div>
          </div>
        )}
        {isModalOpen && (
          <ModalPlayer
            closeModal={handleCancel}
            mediaLink={videoUrl}
            mediaType={mediaType}
          />
        )}
      </div>
    );
  }
}
