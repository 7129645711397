import { Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import "./subTabs.scss";

export default function ComponentListSubTabsComponent({
    data, activeKey, handleSubTabChange
}) {
    const isShared = useSelector(state => state.global.isShared)
    return (
        <div className="sub-tabs-component background-container" style={{ width: "100%" }}>
            <Tabs
                onChange={handleSubTabChange}
                activeKey={activeKey}
                destroyInactiveTabPane
                type="card"
                prefixCls="sub-tabs"
                defaultActiveKey="0"
            >
                {
                    data.map((ele, id) => {
                        console.log('ele', ele)
                        return <Tabs.TabPane tab={ele.title} key={ele.key}>
                            <div className={` ${isShared ? 'shared-sub-tabs-content-wrapper' : 'sub-tabs-content-wrapper'}`}>
                                {ele.component}
                            </div>
                        </Tabs.TabPane>
                    })
                }
            </Tabs>
        </div>
    );
}

