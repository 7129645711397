import moment from "moment";
export function truncateFilename(filename, maxLength = 25) {
  if (filename.length <= maxLength) {
    return filename;
  } else {
    let basename = filename.substring(0, filename.lastIndexOf('.'));
    let extension = filename.substring(filename.lastIndexOf('.'));

    let truncatedName = basename.substring(0, maxLength - extension.length) + '...' + extension;
    return truncatedName;
  }
}


export const formatDate = (timestamp) => {
  const date = moment.utc(timestamp).local();
  const now = moment()
  const duration = moment.duration(now.diff(date));
  const hours = duration.asHours();
  const minutes = duration.asMinutes();

  if (minutes < 60) {
    return `${Math.floor(minutes)} minutes ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hours ago`;
  } else {
    return date.format('MMMM D, YYYY');
  }
}

export const truncate = (text, size = 30) => {
  if (!text) return ''; // Handle case where text is undefined or null
  return text.length > size ? text.substring(0, size) + '...' : text;
};

export const urlShortener = async (url) => {
  try {
    const response = await fetch('https://api-ssl.bitly.com/v4/shorten', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ long_url: url }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    return data.link;
  } catch (error) {
    console.error('Error shortening URL:', error);
    throw new Error('Failed to shorten URL');
  }
};

export function getPlainTextFromWYSIWYG(wysiwygContent) {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = wysiwygContent;
  return tempElement.textContent || tempElement.innerText || "";
}
