import { Tabs } from "antd";
import React, { useState } from "react";
import CardList from "../CardList/CardList";
import { useSelector } from "react-redux";

import "./subTabs.scss";

export default function SubTabsComponent({
    data,
    color,
    onCardClickHandler,
    mediaType,
    videoUrl,
    handleCancel,
    isModalOpen,
    convertToStandardURL,
    PlayVideoHandler,
    levels,
    levelIndexMap,
    levelColor

}) {
    const [aboutUsKey, setAboutUsKey] = useState("0");
    const isShared = useSelector(state => state.global.isShared)

    const changeAboutUsTab = (tabValue) => {
        setAboutUsKey(tabValue)
    }


    return (
        <div className="sub-tabs-component background-container" style={{ width: "100%" }}>
            <Tabs
                onChange={changeAboutUsTab}
                activeKey={aboutUsKey}
                destroyInactiveTabPane
                type="card"
                prefixCls="sub-tabs"
                defaultActiveKey="0"
            >

                {
                    data.map((ele, id) => {
                        console.log('ele', ele)
                        return <Tabs.TabPane tab={ele.assetTitle} key={id}>
                            <div className={` ${isShared ? 'shared-sub-tabs-content-wrapper' : 'sub-tabs-content-wrapper'}`}>
                                <CardList
                                    responseData={ele.subTitles}
                                    color={color}
                                    PlayVideoHandler={PlayVideoHandler}
                                    convertToStandardURL={convertToStandardURL}
                                    isModalOpen={isModalOpen}
                                    handleCancel={handleCancel}
                                    videoUrl={videoUrl}
                                    mediaType={mediaType}
                                    onCardClickHandler={onCardClickHandler}
                                    levels={levels}
                                    levelIndexMap={levelIndexMap}
                                    levelColor={levelColor}
                                />
                            </div>
                        </Tabs.TabPane>
                    })
                }


            </Tabs>
        </div>
    );
}

