import React, { useEffect, useState, useContext } from "react";
import { useFetcher, useLocation } from "react-router-dom";
import SearchBar from "../searchBar/SearchBar";
import "./MicroSites.scss";
import Profile from "../editProfile/profile";
import { Card, Button, Modal, Spin, Tag, Dropdown, Input } from "antd";
import { AudioFilled, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import userapiservice from "../../api/userapiservice";
import { getRequest, postRequest, deleteRequest } from "../../api/http";
import PageNotFound from "../../components/PageNotFound/PageNotFound";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg";
import viewIcon from "../../assets/images/ViewMap.svg";
import { Footer } from "antd/es/layout/layout";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Filter from "./filter";
import { Filter as IconFilter } from "../../components/Icons/Icons";
import { motion } from "framer-motion";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import { RWebShare } from "react-web-share";
import share from "../../assets/images/share.svg";
import organisations from "../../assets/images/organisations.svg";
import home from "../../assets/images/home.svg";
import homeBlue from "../../assets/images/homeBlue.svg";
import fav from "../../assets/images/favorites.svg";
import favBlue from "../../assets/images/favoritesBlue.svg";
import bookmarkedFilled from "../../assets/images/bookmark.svg";
import favorite from "../../assets/newImages/Favorite.svg";
import favoriteActive from "../../assets/newImages/FavoriteActive.svg";
import shareIcon from "../../assets/images/share-icon.svg";
import save from "../../assets/images/save.svg";
import saved from "../../assets/images/saved.svg";
import EditProfileLogo from "../../assets/images/EditProfileIcon.svg";
import EditProfileLogoBlue from "../../assets/images/EditProfileIconBlue.svg";
import EditProfilePage from "../../screens/editProfile/EditProfile";
import TitleBar from "../../components/TitleBar/TitleBar";
import Loader from "../../components/Loader/Loader";
import ViewPage from "../View/View";
import { Back, Favorite, FavoriteActive } from "../../components/Icons/Icons";
import MicroSiteSearchAndFilter from "./MicroSiteSearchAndFilter";
import MapView from "./MapView";
import ListIcon from "../../assets/newImages/list.svg";
import MapIcon from "../../assets/newImages/map.svg";
import youTubePlayButton from "../../assets/newImages/Play.svg";
import { useSelector, useDispatch } from "react-redux";
import { renderList } from "../../Reducer/globalSlice";

export default function MicroSites() {
  const location = useLocation();
  const dispatch = useDispatch();
  const selectorData = useSelector((state) => state.global);
  const typeOfDevice =
    window.innerWidth < 760
      ? "mobile"
      : window.innerWidth < 1023
        ? "tab"
        : "laptop";

  const [selectedMicrosite, setSelectedMicrosite] = useState(null);
  const partner = localStorage.getItem("partner");
  const partnerId = localStorage.getItem("partnerId");
  const { profileOpen, setProfileOpen, openMicrosite, setOpenMicrosite } =
    useContext(GlobalContext);
  const [microsites, setMicrosites] = useState();
  const [getSector, setGetSector] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateFilterData, setUpdateFilterData] = useState(false);
  const [allResponse, setAllResponse] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [af, setAf] = useState("");
  const [gl, setGl] = useState("");
  const [ms, setMs] = useState("");
  const [tz, setTz] = useState("");
  const [boolaf, setBoolAf] = useState(false);
  const [boolgl, setBoolGl] = useState(false);
  const [boolms, setBoolMs] = useState(false);
  const [booltz, setBoolTz] = useState(false);
  const page = "home";
  const [bookmarkData, setBookmarkData] = useState([]);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const baseUrl = window.location.origin;
  const url1 = location?.pathname?.split("/")[1];
  const [activeMicros, setActiveMicros] = useState("both");
  const [loader, setLoader] = useState(false);
  const [radiusValue, setRadiusValue] = useState();
  const [selectedMicroSite, setSelectedMicroSite] = useState();
  const [allowSetView, setAllowSetView] = useState(false);
  const [favMicroSites, setFavMicroSites] = useState(false);
  const filterAvailable = localStorage.getItem("filterAvailable", false);
  console.log("filterAvailable", filterAvailable, typeof filterAvailable);
  const [subSectors, setSubSectors] = useState([]);
  const [sectorId, setSectorId] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : null
  );
  const [subsectorId, setSubsectorId] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : null
  );
  const [selectedEvent, setselectedEvent] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );
  const [size, setSize] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : null
  );

  const [interestName, setinterestName] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );

  const handleCardClick = (microsite) => {
    setSelectedMicrosite(microsite);
    setProfileOpen(true);
    console.log("i am here");
  };

  useEffect(() => {
    if (openMicrosite) {
      OnCardClickHandler(
        selectedMicrosite?.id,
        selectedMicrosite?.mediaLink,
        selectedMicrosite?.name
      );
      setOpenMicrosite(false);
    }
  }, [openMicrosite]);
  console.log("activeMicros", activeMicros);
  useEffect(() => {
    const zoomValue = localStorage?.getItem("zoom");
    if (!zoomValue) {
      localStorage.setItem("zoom", 6);
    }
  }, []);
  //   useEffect(() => {
  //     console.log('here',location?.state?.microType === 'list')
  // if(location?.state?.microType === 'list'){
  //   console.log('indideeeee')
  //   setActiveMicros("list")
  // }
  //   },[])

  useEffect(() => {
    console.log("location", selectorData);
    if (typeOfDevice !== "laptop" && selectorData?.list) {
      // setActiveMicros("view");
      setActiveMicros("list");
      dispatch(renderList(false));
    } else if (typeOfDevice !== "laptop") {
      setActiveMicros("view");
    }
  }, [typeOfDevice]);

  const phoneNumber = localStorage.getItem("Phone Number");
  const accessToken = localStorage.getItem("accessToken");
  const [searchName, setSearchName] = useState(
    localStorage.getItem("searchName")
      ? localStorage.getItem("searchName")
      : null
  );

  const interest = [
    "",
    "PRODUCT",
    "CONTRACTS",
    "SERVICE",
    "VACANCY",
    "APPRENTICESHIP",
    "WORK_EXPERIENCE",
  ];

  const defaultOrganisationType = [""];

  const [organisationTypeName, setOrganisationTypeName] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );

  const [organisationNameById, setOrganisationNameById] = useState(
    localStorage.getItem("organisationNameById")
      ? localStorage.getItem("organisationNameById")
      : null
  );

  const [selectedOrganisationType, setSelectedOrganisationType] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );

  const [cardId, setCardId] = useState();

  const { id } = useParams();
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const [partnerData, setPartnerData] = useState({});
  const [dynamicOrganisationTypes, setDynamicOrganisationTypes] = useState([]);

  const fetchSectorList = async () => {
    try {
      const response = await getRequest(
        userapiservice.getAllSectors(partnerId)
      );
      setGetSector(response);
      // if (sectorId) {
      //   setSectorValue(sectorId);
      //   sectorChange(sectorId);
      // }
      // if (size) {
      //   setSizeValue(size);
      // }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchSectorList();
  }, []);

  useEffect(() => {
    if (activeMicros === "both") {
      setRadiusValue(2);
    }
    // else if (activeMicros === "view") {
    //   setRadiusValue(2);
    // }
  }, [activeMicros]);

  const getPartnerDetails = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        setPartnerData(response);
        setDynamicOrganisationTypes(response.organizationTypes || []);
        localStorage.setItem("partnerId", response.id);
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    getPartnerDetails();
  }, []);

  const organisationType = [
    ...defaultOrganisationType,
    ...dynamicOrganisationTypes,
  ];

  const suffix = (
    <AudioFilled
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );
  const prefix = <SearchOutlined style={{ color: "gray" }} />;
  const viewHandler = () => {
    navigate("/view");
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const getBookmark = async (updateToMicroSites = false) => {
    try {
      const response = await getRequest(
        userapiservice.getBookmark(phoneNumber, partner)
      );
      console.log("response1", response);
      if (response) {
        setBookmarkData(response.microsites);
        if (updateToMicroSites) {
          setMicrosites(response?.microsites);
        }
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const postBookmark = async (id, name) => {
    try {
      const response = await postRequest(
        userapiservice.postBookmark(id, phoneNumber)
      );
      if (response) {
        toast.success(`${name} added to favorites`);
        getBookmark();
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const handleBookmark = (id, name) => {
    postBookmark(id, name);
  };

  useEffect(() => {
    if (phoneNumber && accessToken) {
      getBookmark();
    }
  }, []);

  const fetchMicrosites = async (
    partner,
    organisationTypeName,
    interestName,
    sectorId,
    subsectorId,
    size,
    searchName,
    af,
    gl,
    ms,
    tz
  ) => {
    setLoading(true);
    try {
      await getRequest(
        userapiservice.filterMicrosites(
          partner,
          organisationTypeName,
          interestName,
          sectorId,
          subsectorId,
          size,
          searchName,
          af,
          gl,
          ms,
          tz
        )
      ).then((res) => {
        let response = [...res.result];
        let output = [...res.result],
          subsectorOutput = [],
          sectorOutput = [];

        setAllResponse(response.result);

        setMicrosites([
          ...new Map(output.map((item) => [item["id"], item])).values(),
        ]);

        if (interestName) {
          localStorage.setItem(
            "Interest/AssetType",
            interestName ? interestName : ""
          );
          if (interestName === "PRODUCT") {
            localStorage.setItem("tabValue", "product");
          } else if (interestName === "SERVICE") {
            localStorage.setItem("tabValue", "service");
          } else if (interestName === "CONTRACTS") {
            localStorage.setItem("tabValue", "contract");
          } else if (interestName === "VACANCY") {
            localStorage.setItem("tabValue", "vacancy");
          } else if (interestName === "APPRENTICESHIP") {
            localStorage.setItem("tabValue", "apprenticeship");
          } else if (interestName === "WORK_EXPERIENCE") {
            localStorage.setItem("tabValue", "work-experience");
          } else if (interestName === "COURSES") {
            localStorage.setItem("tabValue", "courses");
          }
        }

        setLoading(false);
      });
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.data && location.state?.data.length > 0) {
      setMicrosites(location.state.data);
    } else
      fetchMicrosites(
        partner,
        selectedOrganisationType,
        selectedEvent,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    setLoading(true);

    // if (token) {
    //   getEndUserData();
    // }
  }, [af, gl, ms, tz]);

  useEffect(() => {
    // if (location.state?.data && location.state?.data.length > 0) {
    //   setMicrosites(location.state.data);
    // } else
    if (favMicroSites) {
      getBookmark(true);
    } else {
      fetchMicrosites(
        partner,
        selectedOrganisationType,
        selectedEvent,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
      setLoading(true);
    }
  }, [favMicroSites]);

  const OnCardClickHandler = async (id, logo, name) => {
    // localStorage.setItem("micrositeID", id);
    console.log("name", name);
    const formattedName = name.replace(/ /g, "_");
    localStorage.setItem("micrositeName", formattedName);
    navigate(`/microsite/${formattedName}`, {
      state: { page: "home", logo: { logo }, microType: "list" },
    });
  };

  const GetValueFromSearch = (data) => {
    setMicrosites(data);
  };

  const RemoveHandler = (value) => {
    localStorage.removeItem(value);
    localStorage.setItem("filterAvailable", false);
    console.log('value', value)
    if (value == "organisationType") {
      setOrganisationTypeName(null);
      setSelectedOrganisationType(null);
      localStorage.removeItem("OrganisationType");
      localStorage.removeItem("organisationNameById")

      fetchMicrosites(
        partner,
        null,
        interestName,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "interest") {
      localStorage.removeItem("Interest/AssetType");
      localStorage.removeItem("tabValue");
      setinterestName(null);
      setselectedEvent(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        null,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "sector") {
      setSectorId(null);
      setSubsectorId(null);
      setSubSectors(null);
      localStorage.removeItem("subsector");
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        null,
        null,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "subsector") {
      setSubsectorId(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        sectorId,
        null,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "size") {
      setSize(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        sectorId,
        subsectorId,
        null,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "searchName") {
      setSearchName(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        sectorId,
        subsectorId,
        size,
        null,
        af,
        gl,
        ms,
        tz
      );
    }
    setUpdateFilterData(!updateFilterData);
  };

  const FindSectorName = (id) => {
    let response = "";
    getSector.map((data) => {
      if (id === data.id) {
        response = data.name;
      }
    });
    return response;
  };

  const getSubSector = async (sectorId) => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      const response = await getRequest(userapiservice.getSubSectors(sectorId));

      setSubSectors(response);
      // setSubsectorValue(subSectorId);
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const FindSubSectorName = (id) => {
    let response = "";

    subSectors.map((data) => {
      if (id === data.id) {
        response = data.name;
      }
    });
    return response;
  };

  const setFilterHandler = (
    list,
    organisationTypeName,
    interestName,
    sectorId,
    subSectorIds,
    size
  ) => {
    setOrganisationTypeName(organisationTypeName);
    setinterestName(interestName);
    setSectorId(sectorId);
    setSubsectorId(subSectorIds);
    setSize(size);
    setMicrosites(list);
  };
  const removeBookmark = async (id, name) => {
    try {
      const response = await deleteRequest(
        userapiservice.removeBookmark(id, phoneNumber)
      );

      if (response) {
        getBookmark();
        toast.success(`${name} removed from favorites`);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const closeModalOfProfile = () => {
    setProfileOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const editProfileClickHandler = () => {
    setShowEditProfile(true);
  };

  const closeModal = () => {
    setShowEditProfile(false);
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getSubSector(sectorId);
    localStorage.setItem("Page", "home");
  }, [sectorId]);

  const handleAF = () => {
    if (!boolaf) {
      setAf("A-F");
      setBoolAf(true);
    } else {
      setAf("");
      setBoolAf(false);
    }
  };

  const handleGL = () => {
    if (!boolgl) {
      setGl("G-L");
      setBoolGl(true);
    } else {
      setGl("");
      setBoolGl(false);
    }
  };

  const handleMS = () => {
    if (!boolms) {
      setMs("M-S");
      setBoolMs(true);
    } else {
      setMs("");
      setBoolMs(false);
    }
  };

  const handleTZ = () => {
    if (!booltz) {
      setTz("T-Z");
      setBoolTz(true);
    } else {
      setTz("");
      setBoolTz(false);
    }
  };

  const BackButtonClick = () => {
    navigate("/")
  };

  const onSearchClick = async (searchText) => {
    try {
      setLoader(true);

      const response = await getRequest(
        userapiservice.filterMicrosites(partner, "", "", "", "", "", searchText)
      );

      setMicrosites(response?.result);
      setAllowSetView(true);
    } catch (error) {
      toast.error(error.error);
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };



  return (
    <div className="microsites-container">
      <div className="search-and-filter-wrapper">
        <div className="arrow-container">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              BackButtonClick();
            }}
          >
            <Back />
          </div>
          {/* <span className="title">Microsites List</span> */}
          <span className="title">Browse Organisations</span>
        </div>

        <div className="search-and-filter-container">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (accessToken) {
                setFavMicroSites(!favMicroSites);
              } else {
                toast.error("Please login to access Favorites");
              }
            }}
          >
            <img
              // src={ favMicroSites ?  bookmarkedFilled : save}
              src={favMicroSites ? favoriteActive : favorite}
              className="fav-icon"
            />
          </div>
          <div className="title-bar-search-wrapper">
            <Input
              className="title-bar-search"
              placeholder={"Search Organisations"}
              // value={''}
              suffix={
                <SearchOutlined
                  style={{ color: "#CC0E2D", verticalAlign: "middle" }}
                />
              }
              onChange={(e) => {
                onSearchClick(e?.target?.value);
              }}
            />
          </div>
          <div className="filter-container">
            <div>
              {!boolaf ? (
                <Button
                  id="A-F-btn"
                  // type="primary"
                  className="alpha-filter-btn alpha-filter"
                  style={{ backgroundColor: "rgba(198, 164, 230, 0.7)" }}
                  onClick={handleAF}
                >
                  <span style={{ color: "white" }}>A-F</span>
                </Button>
              ) : (
                <Button
                  id="A-F-btn"
                  //  type="primary"
                  className="alpha-filter-btn alpha-filter"
                  style={{
                    backgroundColor: "rgba(198, 164, 230, 0.7)",
                    border: "1.5px solid #B771F9",
                  }}
                  onClick={handleAF}
                >
                  <span style={{ color: "#fff", fontWeight: "bold" }}>A-F</span>
                </Button>
              )}
              {!boolgl ? (
                <Button
                  id="G-L-btn"
                  type="primary"
                  className="alpha-filter-btn alpha-filter"
                  style={{ backgroundColor: "rgba(242, 119, 148, 0.7)" }}
                  onClick={handleGL}
                >
                  <span style={{ color: "white" }}>G-L</span>
                </Button>
              ) : (
                <Button
                  id="G-L-btn"
                  className="alpha-filter-btn alpha-filter"
                  style={{
                    backgroundColor: "rgba(242, 119, 148, 0.7)",
                    border: "1.5px solid #FE3464",
                  }}
                  onClick={handleGL}
                >
                  <span style={{ color: "#fff", fontWeight: "bold" }}>G-L</span>
                </Button>
              )}
              {!boolms ? (
                <Button
                  id="M-S-btn"
                  type="primary"
                  className="alpha-filter-btn alpha-filter"
                  style={{ backgroundColor: "rgba(155, 224, 124, 0.7)" }}
                  onClick={handleMS}
                >
                  <span style={{ color: "white" }}>M-S</span>
                </Button>
              ) : (
                <Button
                  id="M-S-btn"
                  className="alpha-filter-btn alpha-filter"
                  style={{
                    // backgroundColor: "rgba(185, 230, 165, 0.7)",
                    backgroundColor: "rgba(155, 224, 124, 0.7)",


                    border: "1.5px solid #65AD45",
                  }}
                  onClick={handleMS}
                >
                  <span style={{ color: "#fff", fontWeight: "bold" }}>M-S</span>
                </Button>
              )}
              {!booltz ? (
                <Button
                  id="T-Z-btn"
                  type="primary"
                  className="alpha-filter-btn alpha-filter"
                  style={{ backgroundColor: "rgba(114, 225, 213, 0.7)" }}
                  onClick={handleTZ}
                >
                  <span style={{ color: "white" }}>T-Z</span>
                </Button>
              ) : (
                <Button
                  id="T-Z-btn"
                  className="alpha-filter-btn alpha-filter"
                  style={{
                    backgroundColor: "rgba(114, 225, 213, 0.7)",
                    border: "1.5px solid #1EB8A7",
                  }}
                  onClick={handleTZ}
                >
                  <span style={{ color: "#fff", fontWeight: "bold" }}>T-Z</span>
                </Button>
              )}
              <Filter
                setData={setFilterHandler}
                pageType="Homepage"
                updateFilterData={updateFilterData}
                selectedOrganisationType={selectedOrganisationType}
                setSelectedOrganisationType={setSelectedOrganisationType}
                setOrganisationNameById={setOrganisationNameById}
                // dynamicOrganisationTypes={dynamicOrganisationTypes}
                selectedEvent={selectedEvent}
                setselectedEvent={setselectedEvent}
                title="Filter"
                className={
                  "button-primary form-submit-btn text-regular filter-icon text-white"
                }
              ></Filter>
              {/* <Button
              id="submit-btn"
              type="primary"
              htmlType="submit"
              className="view-btn"
              onClick={viewHandler}
            >
              <span style={{ color: "white" }}>View</span>
              <div className="view-img">
                <img src={viewIcon} alt="main logo" />
              </div>
            </Button> */}
            </div>
            {typeOfDevice !== "laptop" && (
              <img
                onClick={() => {
                  if (activeMicros === "list") {
                    setActiveMicros("view");
                  } else {
                    setActiveMicros("list");
                  }

                  // setActiveMicros("list");
                }}
                src={activeMicros === "list" ? MapIcon : ListIcon}
                className="toggle-img"
                alt="map"
              />
            )}
          </div>
        </div>
      </div>
      {organisationTypeName || organisationNameById || interestName || sectorId || subsectorId || size || searchName ?
        <div className="filter-options">
          {console.log('organisationNameById', organisationNameById)}
          {organisationTypeName && organisationNameById ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "organisationType")}
            >
              {/* // <Tag closable> */}
              {organisationNameById ? organisationNameById : ""}
            </Tag>
          ) : null}
          {interestName ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "interest")}
            >
              {/* // <Tag closable> */}
              {interestName === "APPRENTICESHIP"
                ? "Apprenticeships"
                : interestName === "VACANCY"
                  ? "Vacancies"
                  : interestName === "PRODUCT"
                    ? "Products"
                    : interestName === "CONTRACTS"
                      ? "Contracts"
                      : interestName === "SERVICE"
                        ? "Service"
                        : interestName === "WORK_EXPERIENCE"
                          ? "Experiences of work"
                          : interestName === "COURSES"
                            ? "Courses"
                            : ""}
            </Tag>
          ) : null}
          {sectorId ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "sector")}
            >
              {FindSectorName(sectorId)}
            </Tag>
          ) : null}
          {subsectorId ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "subsector")}
            >
              {FindSubSectorName(subsectorId)}
            </Tag>
          ) : null}
          {size ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "size")}
            >
              {size === "MICRO"
                ? "Micro 0-9"
                : size === "SMALL"
                  ? "Small 10-49"
                  : size === "MEDIUM"
                    ? "Medium 50-249"
                    : size === "LARGE"
                      ? "Large 250+"
                      : ""}
            </Tag>
          ) : null}
          {searchName ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "searchName")}
            >
              {searchName}
            </Tag>
          ) : null}
        </div> : ''}

      <div className="list-and-view-container">
        {activeMicros === "view" && (
          <div
            className="view-container"
            style={{ width: activeMicros === "view" && "100%" }}
          >
            <div className="arrows-wrapper">
              <div></div>
              <div className="arrows-container">
                {typeOfDevice === "laptop" ? (
                  <>
                    <div
                      onClick={() => {
                        setActiveMicros("both");
                      }}
                      className="arrows"
                    >
                      &larr;
                    </div>
                    <div
                      onClick={() => {
                        setActiveMicros("view");
                        // setRadiusValue(11)
                      }}
                      className="arrows"
                    >
                      &rarr;
                    </div>
                  </>
                ) : typeOfDevice === "tab" ? (
                  <img
                    onClick={() => {
                      setActiveMicros("list");
                    }}
                    src={ListIcon}
                    className="responsive-view-img"
                    alt="map"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <MapView
            className="ant-map"
              microSitesData={microsites}
              expandMap={false}
              allowSetView={allowSetView}
              selectedData={(val) => {
                setSelectedMicroSite(val);
              }}
              disableSetView={() => {
                setAllowSetView(false);
              }}
              cardData={selectedMicroSite}
              cardState={selectedMicroSite ? true : false}
              previewCardState={
                Object.keys(selectedMicroSite || {})?.length ? true : false
              }
            />
          </div>
        )}

        {activeMicros === "both" || activeMicros === "list" ? (
          <div
            className="list-container"
            style={{ width: activeMicros === "list" && "100%" }}
          >
            <div className="arrows-wrapper">
              <div></div>
              <div className="arrows-container">
                {typeOfDevice === "laptop" ? (
                  <>
                    <div
                      onClick={() => {
                        setActiveMicros("both");
                      }}
                      className="arrows"
                    >
                      &larr;
                    </div>
                    <div
                      onClick={() => {
                        setActiveMicros("list");
                      }}
                      className="arrows"
                    >
                      &rarr;
                    </div>
                  </>
                ) : typeOfDevice === "tab" ? (
                  <img
                    onClick={() => {
                      setActiveMicros("list");
                    }}
                    src={ListIcon}
                    className="responsive-view-img"
                    alt="map"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <>
                <motion.div
                  //   className="list-card-container"
                  className={
                    activeMicros === "list"
                      ? microsites && microsites.length === 0
                        ? "empty-micros"
                        : "list-card-container list-grid"
                      : microsites && microsites.length === 0
                        ? "empty-micros"
                        : "list-card-container"
                  }
                >
                  {microsites && microsites.length > 0 ? (
                    microsites.map((microsite) => (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                          duration: 0.8,
                          delay: 0.5,
                          ease: [0, 0.71, 0.2, 1.01],
                        }}
                        onClick={() => handleCardClick(microsite)}
                        className="motion-wrapper"
                      >
                        <Card
                          style={{
                            border:
                              selectedMicroSite?.micrositeLogo ===
                                microsite?.micrositeLogo
                                ? "1px solid red"
                                : "",
                          }}
                          className="card-wrapper"
                        >
                          <div className="card-container">
                            <div className="card-image-container">
                              {microsite.carouselLink?.mediaType ===
                                "SOCIAL_MEDIA" &&
                                microsite.carouselLink?.url ? (
                                <>
                                  {(() => {
                                    const standardURL = convertToStandardURL(
                                      microsite.carouselLink.url
                                    );
                                    const videoID = standardURL
                                      .split("v=")[1]
                                      ?.split("&")[0];
                                    return (
                                      <div
                                        className="media-image-container media1 youtube1"
                                        style={{
                                          objectFit: "contain",
                                          borderRadius: "8px",
                                        }}
                                        onClick={() =>
                                          PlayVideoHandler(
                                            microsite.carouselLink?.url,
                                            microsite.carouselLink?.mediaType
                                          )
                                        }
                                      >
                                        <img
                                          src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                          alt=""
                                          className="video-preview-image image-preview"
                                        // style={{
                                        //   //   height: "100%",
                                        //   // minHeight: "120px",
                                        // //   height: "120px",
                                        // //   borderRadius: "8px",
                                        // //   objectFit: "cover",

                                        // }}
                                        />
                                        {bookmarkData.filter(
                                          (item) => item.id === microsite.id
                                        ).length > 0 ? (
                                          <img
                                            className="bookMarked"
                                            // src={bookmarkedFilled}
                                            src={favoriteActive}
                                            alt="bookmark"
                                          />
                                        ) : null}
                                        <img
                                          // className="ytBtnOverlay"
                                          className="play-button"
                                          src={youTubePlayButton}
                                        />
                                      </div>
                                    );
                                  })()}
                                </>
                              ) : microsite.carouselLink?.mediaType ===
                                "VIMEO_MEDIA" &&
                                microsite.carouselLink?.url ? (
                                <div
                                  className="media-image-container media vimeo youtube"
                                  onClick={() => {
                                    PlayVideoHandler(
                                      microsite.carouselLink?.url,
                                      microsite.carouselLink?.mediaType
                                    );
                                  }}
                                >
                                  <img
                                    src={`https://vumbnail.com/${microsite.carouselLink?.url?.split("/")[4]
                                      }.jpg`}
                                    className="video-preview-image image-preview"
                                  //   style={{
                                  //   height: "100%",
                                  // borderRadius: "8px",
                                  //   minHeight: "120px",
                                  // height: "120px",
                                  // objectFit: "cover",
                                  //   }}
                                  />
                                  {bookmarkData.filter(
                                    (item) => item.id === microsite.id
                                  ).length > 0 ? (
                                    <img
                                      className="bookMarked"
                                      // src={bookmarkedFilled}
                                      src={favoriteActive}
                                      alt="bookmark"
                                    />
                                  ) : null}
                                  <img
                                    //   className="ytBtnOverlay"
                                    className="play-button"
                                    src={youTubePlayButton}
                                  />
                                </div>
                              ) : (
                                <div className="media">
                                  <div className="slider-Img">
                                    <img
                                      src={microsite.carouselLink?.url}
                                      alt=""
                                      className="home-preview-img"
                                    />
                                  </div>
                                  {bookmarkData.filter(
                                    (item) => item.id === microsite.id
                                  ).length > 0 ? (
                                    <img
                                      className="bookMarked"
                                      // src={bookmarkedFilled}
                                      src={favoriteActive}
                                      alt="bookmark"
                                    />
                                  ) : null}
                                </div>
                              )}
                            </div>
                            <div
                              className="card-description-container"
                              onClick={() => {
                                if (
                                  localStorage.getItem("age") ||
                                  localStorage.getItem("accessToken")
                                ) {
                                  setOpenMicrosite(true);
                                }
                                setProfileOpen(true);
                                console.log("i am here");
                              }}
                            >
                              <div className="micro-share-and-logo-container">
                                <div className="logo-and-share-wrapper">
                                  <div
                                    className="logo-container"
                                  // onClick={() => {
                                  //   OnCardClickHandler(
                                  //     microsite.id,
                                  //     microsite.mediaLink,
                                  //     microsite.name
                                  //   );
                                  // }}
                                  >
                                    <img
                                      src={microsite.micrositeLogo}
                                      alt={microsite.name}
                                      //   className="preview-image"
                                      className="microsite-preview-image"
                                    />
                                  </div>
                                </div>
                                <div className="kebab-menu">
                                  <Dropdown
                                    trigger={["click"]}
                                    placement="bottomRight"
                                    overlay={
                                      <div
                                        className="microsite-dropdown"
                                        style={{
                                          border: "1px solid #f0f0f0",
                                          borderRadius: "4px",
                                          backgroundColor: "#fff",
                                          minWidth: "170px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            padding: "10px",
                                            borderBottom: "1px solid #f0f0f0",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div className="share-dropdown">
                                            <RWebShare
                                              data={{
                                                url: `${baseUrl}/microsite/${microsite.name.replace(
                                                  / /g,
                                                  "_"
                                                )}?partnerId=${partnerData.id}`,
                                              }}
                                            >
                                              <span>
                                                <img
                                                  src={shareIcon}
                                                  alt="share"
                                                />
                                                <span
                                                  style={{
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  Share
                                                </span>
                                              </span>
                                            </RWebShare>
                                          </div>
                                        </div>
                                        {phoneNumber && accessToken ? (
                                          bookmarkData.filter(
                                            (item) => item.id === microsite.id
                                          ).length > 0 ? (
                                            <div
                                              style={{
                                                padding: "10px",
                                                cursor: "pointer",
                                                display: "flex",
                                              }}
                                              onClick={(e) => {
                                                removeBookmark(
                                                  microsite.id,
                                                  microsite.name
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              {/* <img src={favoriteActive} alt="share" /> */}
                                              <img
                                                width={18}
                                                src={favoriteActive}
                                                alt="share"
                                              />

                                              <span
                                                style={{
                                                  marginLeft: "5px",
                                                }}
                                              // onClick={(e) => {
                                              //   removeBookmark(
                                              //     microsite.id,
                                              //     microsite.name
                                              //   );
                                              //   e.stopPropagation();
                                              // }}
                                              >
                                                Remove from Favorite
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              className="fav-wrapper"
                                              style={{
                                                padding: "10px",
                                                cursor: "pointer",
                                                display: "flex",
                                              }}
                                              onClick={(e) => {
                                                handleBookmark(
                                                  microsite.id,
                                                  microsite.name
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              <img
                                                width={18}
                                                src={favorite}
                                                // src={save}
                                                className="save-as-fav"
                                                alt="share"
                                                style={{
                                                  marginRight: "5px",
                                                }}
                                              />
                                              Save as Favorite
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className="save-as-fav"
                                            style={{
                                              padding: "10px",
                                              cursor: "not-allowed",
                                              opacity: 0.5,
                                            }}
                                          >
                                            {/* <img src={save} alt="share" /> */}
                                            <img
                                              width={18}
                                              className="save-as-fav-img"
                                              src={favorite}
                                              alt="share"
                                            />

                                            <span
                                              style={{ marginLeft: "5px" }}
                                              onClick={(e) => {
                                                toast.error(
                                                  "Please login to access Favorites"
                                                );
                                                e.stopPropagation();
                                              }}
                                            >
                                              Save as Favorite
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    }
                                  >
                                    <img
                                      onClick={(e) => e.stopPropagation()}
                                      className="share-icon"
                                      src={share}
                                      alt="Options"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </Dropdown>
                                </div>
                              </div>

                              <div className="sector-text-container">
                                <span className="microsite-details-text">
                                  {microsite.businessDescription}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </motion.div>
                    ))
                  ) : (
                    <div className="no-micros">
                      {microsites && microsites.length === 0 && (
                        <div>
                          "Sorry there are no organisations at present for this
                          search so try removing filters"
                        </div>
                      )}
                    </div>
                  )}
                </motion.div>
              </>
            )}
          </div>
        ) : (
          ""
        )}

        {activeMicros === "both" ? (
          <div className="view-container">
            <div className="arrows-wrapper">
              <div></div>
              <div className="arrows-container">
                <div
                  onClick={() => {
                    setActiveMicros("both");
                  }}
                  className="arrows"
                >
                  &larr;
                </div>
                <div
                  onClick={() => {
                    setActiveMicros("view");
                    // setRadiusValue(11)
                  }}
                  className="arrows"
                >
                  &rarr;
                </div>
              </div>
            </div>
            <MapView
              disableSetView={() => {
                setAllowSetView(false);
              }}
              microSitesData={microsites}
              selectedData={(val) => {
                setSelectedMicroSite(val);
              }}
              cardData={selectedMicroSite}
              previewCardState={
                Object.keys(selectedMicroSite || {})?.length ? true : false
              }
              allowSetView={allowSetView}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      {/* {microsites?.length === 0 && (
        <div className="no-data-warning">
          <div>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PageNotFound
                message={
                  "Sorry there are no organisations at present for this search so try removing filters"
                }
              />
            </span>
          </div>
        </div>
      )} */}

      {/* {loading && <Loader />} */}

      {isModalOpen ? (
        <ModalPlayer
          closeModal={handleCancel}
          mediaLink={videoUrl}
          mediaType={mediatype}
        />
      ) : (
        ""
      )}
      {profileOpen &&
        !localStorage.getItem("age") &&
        !localStorage.getItem("accessToken") ? (
        <Profile closeModal={closeModalOfProfile} />
      ) : (
        <></>
      )}
    </div>
  );
}
