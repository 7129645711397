import {
  Form,
  Input,
  Select,
  Tooltip,
  Upload
} from "antd";
import FormItem from "antd/es/form/FormItem";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { postRequest } from "../../api/http";
import userServiceApi from "../../api/userapiservice";
import { FileUpload } from "../../components/Icons/Icons";
import TitleBar from "../../components/TitleBar/TitleBar";
import { GlobalContext } from "../../components/globalContext/GlobalContext";
import "./Feedback.scss";

function FeedBack() {
  const [loading, setLoading] = useState(false);
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState(null);
  const partnerId = localStorage.getItem("partnerId");
  const [form] = useForm();
  const [fileSizeOk, setFileSizeOk] = useState(false);
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const checkFile = (file) => {
    const maxSize = 2.5 * 1024 * 1024;
    const isSizeValid = file.size <= maxSize;

    if (!isSizeValid) {
      setFileSizeOk(false);
      toast.error("File size exceeds 2.5MB!");
    } else {
      setFileSizeOk(true);
    }

    return isSizeValid;
  };

  const onFinish = async (values) => {
    try {
      if (file) {
        if (fileSizeOk) {
          setLoading(true);
          if (
            values &&
            values.message &&
            values.email &&
            values.feedbackAboutEnum
          ) {
            var formData = new FormData();
            if (file) {
              formData.append("file", file, fileName);
            }
            const formatedData = {
              feedbackAboutEnum: values.feedbackAboutEnum,
              message: values.message,
              email: values.email,
              ageTagId: localStorage.getItem("ageID"),
            };
            const json = JSON.stringify(formatedData);
            const blob = new Blob([json], {
              type: "application/json",
            });
            formData.append("endUserFeedbackDto", blob);
            // console.log(data)
            const response = await postRequest(
              userServiceApi.postFeedbackForm(partnerId),
              formData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response) {
              setFileName(null);
              toast.success("Feedback sent successfully");
              setFileSizeOk(false);
              form.resetFields();
              setLoading(false);
            }
          }
        } else {
          toast.error("File size exceeds 2.5MB!");
        }
      } else {
        setLoading(true);
        if (
          values &&
          values.message &&
          values.email &&
          values.feedbackAboutEnum
        ) {
          var formData = new FormData();
          const formatedData = {
            feedbackAboutEnum: values.feedbackAboutEnum,
            message: values.message,
            email: values.email,
            ageTagId: localStorage.getItem("ageID"),
          };
          const json = JSON.stringify(formatedData);
          const blob = new Blob([json], {
            type: "application/json",
          });
          formData.append("endUserFeedbackDto", blob);
          // console.log(data)
          const response = await postRequest(
            userServiceApi.postFeedbackForm(partnerId),
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response) {
            toast.success("Feedback sent successfully");
            form.resetFields();
            setLoading(false);
          }
        }
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const handleUploadAPI = ({ file }) => {
    const fileName = file.name;
    setFileName(fileName);
    const blob = new Blob([file], {
      type: "multipart/form-data",
    });
    setFile(blob);

    // formData.append("file", blob);

    if (Object.values(fileList).length > 0) {
    } else {
      setFileList((pre) => {
        return { ...pre, [file.uid]: file };
      });
    }
  };

  const uploadProps = {
    accept: ".pdf, .jpeg, .png, .jpg",
    beforeUpload: checkFile,
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    customRequest: handleUploadAPI,
  };

  //   if (loading) return <LoadingView message="Loading..." />;

  return (
    <div div className="feedback-wrapper bg-white d-flex flex-column" >
      <TitleBar title={"Support & Feedback"} />
      <div className="feedback-container form-container-feedback">
        <Form
          className="feedback-form"
          form={form}
          labelCol={{
            xs: { span: 24 }, // For extra small screens (mobile), label takes full width
            sm: { span: 5 }, // for all other sizes
          }}
          labelAlign="left"
          onFinish={onFinish}
          layout={globalState.isMobile ? 'vertical' : 'horizontal'}
        >
          <Form.Item
            className="form-item-feedback"
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Enter valid email" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            className="form-item-feedback"
            label="I want to"
            name="feedbackAboutEnum"
            rules={[
              {
                required: true,
                message: "Feedback type is required",
              },
            ]}
          >
            <Select name="feedbackAboutEnum" placeholder="I want to..">
              <Select.Option value="COMMENT">Leave a comment</Select.Option>
              <Select.Option value="REPORT">Report a bug</Select.Option>
              <Select.Option value="SUGGESTION">
                Suggest an improvement
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item-feedback"
            rules={[
              {
                required: true,
                message: "Feedback message is required",
              },
            ]}
            label="Message"
            name="message"
          >
            <Input.TextArea
              maxLength={250}
              showCount
              rows={3}
              placeholder="Message"
            />
          </Form.Item>
          <Form.Item
            className="form-item-feedback"
            label="Document"
            name="feedbackDocument"
          >
            <div className="files-feedback">
              <div className="file-upload-feedback">
                <Tooltip
                  title={"Upload image or pdf with size less than 2.5MB"}
                >
                  <div className="upload-component">
                    <Dragger {...uploadProps} className="dropbox">
                      <div className="box-content">
                        <p className="ant-upload-drag-icon">
                          <FileUpload />
                        </p>
                        <p className="feedback-form-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </div>
                    </Dragger>
                  </div>
                </Tooltip>
              </div>
              <div className="file-list">
                {fileName ? (
                  <span className="file-list-item">
                    Selected<span className="doc-name">{` ${fileName}`}</span>
                  </span>
                ) : (
                  <span className="file-list-item">
                    <span>No document uploaded</span>
                  </span>
                )}
              </div>
            </div>
          </Form.Item>
          <FormItem label="" className="form-item-feedback"
            colon={false}>
            <div className="submitBtn-feedback">
              <button className="submit-feedback-btn btn btn-danger" htmlType="submit">
                Submit
              </button>
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
  );
}

export default FeedBack;
