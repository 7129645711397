import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Back } from '../Icons/Icons';
import './titlebar.scss';

const TitleBar = ({ title, hideBackBtn, navigateToUrl, search, searchTerm = '', setSearchTerm, searchPlaceholder = "Search",
    micrositeLogoSrc = ''
}) => {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <div className='d-flex title-bar-wrapper bg-white justify-content-between align-items-center' >
            <div className="d-flex align-items-center justify-content-center " >
                {!hideBackBtn && <span className='back-button' onClick={() => { navigateToUrl ? navigate(navigateToUrl) : handleBack() }} >
                    <Back />
                </span>}
                {title && <h1 className='title d-inline-block' >
                    {title}
                </h1>}
            </div>
            {micrositeLogoSrc && <div className="microsite-logo-container ">
                <img src={micrositeLogoSrc} alt="" height='100%' width='100%' />
            </div>}
            {search && <div className="title-bar-search-wrapper">
                <Input
                    className="title-bar-search"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    suffix={
                        <SearchOutlined
                            style={{ color: "#CC0E2D", verticalAlign: "middle" }}
                        />
                    }
                    onChange={(e) => {
                        if (setSearchTerm) {
                            setSearchTerm(e.target.value)
                        }
                    }}
                />
            </div>}
        </div>
    )
}

export default TitleBar