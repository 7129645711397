import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { RWebShare } from "react-web-share";
import { GlobalContext } from "../globalContext/GlobalContext";
import { Help, HelpActive, Home, HomeActive, Share, Support, SupportActive } from '../Icons/Icons';

import { urlShortener } from "../../utils/helper";
import './footer.scss';

const Footer = () => {
    const { globalState, setGlobalState } = useContext(GlobalContext);
    const [shortenedUrl, setShortenedUrl] = useState(window.location.href)
    const micrositeName = localStorage.getItem('micrositeName')
    const partner = useSelector(state => state.global.partner)
    const localStoragePartnerId = localStorage.getItem('partnerId')
    const localStoragePartnerName = localStorage.getItem('partnerName')
    const footerLinks = [
        { displayName: 'Share web link', link: `#`, },
        { displayName: 'Help', link: '/help', },
        { displayName: 'Support and feedback', link: '/feedback', onclick: () => { } }
    ]
    const location = useLocation();

    const getShortenUrl = async () => {

        console.log('window', window.location.href.includes('micrositeDetails'))
        console.log('window', window)
        const shareFromMicroDetail = window?.location?.href?.includes('micrositeDetails')
        let url = ''
        // micrositeName in local storage is saved with " " for sharing replace it with "_"
        const microDetailUrl = `${window?.origin}/microsite/${micrositeName.replace(/ /g, "_")}`

        url = `${shareFromMicroDetail ? microDetailUrl :
            window.location.href}?partnerId=${partner?.id ||
            localStoragePartnerId}&partnerName=${encodeURIComponent(partner?.name ||
                localStoragePartnerName)}`
        try {
            const shortUrl = await urlShortener(url);
            setShortenedUrl(shortUrl);
        } catch (error) {
            console.error("Error shortening URL:", error);
            setShortenedUrl(url); // Fallback to original URL
        }
    };


    useEffect(() => {
        getShortenUrl();
        console.log('partner', partner)
    }, [partner, location.pathname]);


    if (globalState.isMobile) {
        return <footer className="footer-mobile-layout ">
            <ul className="navbar-nav footer-links-container d-flex justify-content-between  align-items-center flex-row h-100">
                <li className="nav-item">
                    <a href="https://www.connectsu.co.uk" target="_blank" className="">
                        <img className='footer-image' src={require("../../assets/images/PoweredNew.png")} width="40" height="40" />
                    </a >
                </li>
                <li style={{
                    borderRight: '2px solid #BAC4D2', display: 'inline-block',
                    width: '2px', height: '10px'
                }} >  &nbsp;</li>
                <li className="nav-item ">
                    <NavLink to={"/"} >
                        {
                            location.pathname === "/" || location.pathname === "/frontend/Partner_Name" ? <HomeActive /> : <Home />
                        }
                    </NavLink>
                </li>
                <li className="nav-item "  >
                    <RWebShare data={{
                        url: shortenedUrl,
                    }}>
                        <span>
                            <Share />
                        </span>
                    </RWebShare>
                </li>
                <li className="nav-item ">
                    <NavLink to={"/help"} >
                        {
                            location.pathname === "/help" ? <HelpActive /> : <Help />
                        }
                    </NavLink>
                </li>
                <li className="nav-item ">
                    <NavLink to={"/feedback"} >
                        {
                            location.pathname === "/feedback" ? < SupportActive /> : < Support />
                        }
                    </NavLink>
                </li>
            </ul>
        </footer>
    }

    return (
        <footer className='footer-desktop-layout row m-0' >
            <div className="footer-image-container col-4 col-md-5 col-lg-6">
                <img
                    style={{ cursor: 'pointer' }}
                    className='footer-image'
                    src={require("../../assets/images/PoweredNew.png")}
                    onClick={() => window.open("https://www.connectsu.co.uk", "_blank")}
                    alt="Powered By ConnectSU"
                />
            </div>
            <ul className="footer-links navbar-nav d-flex flex-row justify-content-around align-items-center col-8 col-md-6 col-lg-5">
                {footerLinks.map((ele, i) => {
                    return <React.Fragment key={i}>
                        {/* TODO onclick for sharing site */}
                        <li className="nav-item"  >
                            {
                                ele.displayName === 'Share web link' ?
                                    <RWebShare data={{
                                        url: shortenedUrl,
                                    }} >
                                        <NavLink to={ele.link} className={`${location.pathname === ele.link ? 'active text-danger' : 'text-primary'} nav-link ${ele.classNames}`}>
                                            {ele.displayName}
                                        </NavLink>
                                    </RWebShare>
                                    : <NavLink to={ele.link} className={`${location.pathname === ele.link ? 'active text-danger' : 'text-primary'} nav-link ${ele.classNames}`}>
                                        {ele.displayName}
                                    </NavLink>
                            }
                        </li>
                        {i !== footerLinks.length - 1 ? <li style={{
                            borderRight: '2px solid #BAC4D2', display: 'inline-block',
                            width: '2px', height: '10px'
                        }} >  &nbsp;</li> : null}
                    </React.Fragment>
                })}
            </ul>
        </footer>
    )
}

export default Footer